import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProductInventory, webshopAPI } from "../../../api/WebshopAPI";

const queryKey = webshopAPI.fetchProductInventory().queryKey;

type QueryDataType =
  | Awaited<
      ReturnType<ReturnType<typeof webshopAPI.fetchProductInventory>["queryFn"]>
    >
  | undefined;

export const useDisableProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: webshopAPI.flagOutOfStock,
    onMutate: async (articleId) => {
      await queryClient.cancelQueries({ queryKey });

      const previousInventory =
        queryClient.getQueryData<ProductInventory[]>(queryKey);

      queryClient.setQueryData<QueryDataType>(queryKey, (old) => {
        if (!old) {
          return undefined;
        }

        return old.map((item) => {
          if (item.articleId === articleId) {
            return { ...item, inStock: false };
          }
          return item;
        });
      });

      return { previousInventory };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousInventory);
    },
  });
};
