import BlockIcon from "@mui/icons-material/Block";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  IconButton,
  Skeleton,
  Stack,
  Switch,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { CandyType } from "../../api/CandyAPI";
import { SortDirection } from "../../api/Page";
import {
  PiecePrice,
  ProviderStatus,
  piecePriceAPI,
} from "../../api/PiecePriceAPI";
import { useAddToPrint } from "../../api/PrintQueueAPI";
import { AddToOrderDialog } from "../../components/AddToOrderDialog";
import { CandyTypeButton, CandyTypeChip } from "../../components/CandyTypeChip";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { MuiLink } from "../../components/MuiLink";
import { FormattedDate } from "../../components/date/FormattedDate";
import { CandyPage } from "../../components/layout/CandyPage";
import { SortLabel, getComparator } from "../../components/table/SortLabel";
import { useSnackBar } from "../../hooks/useSnackbar";
import { ARTICLE_PAGE_ROUTE } from "../Article/ArticlePage/ArticlePage";
import { SetPriceDialog } from "./SetPriceDialog";
import { LABEL_PRINT_PAGE_BASE_ROUTE } from "../LabelPrintPage";

export const PIECE_PRICE_PAGE_ROUTE = "/piece-prices";

const TYPE_FILTER = [
  CandyType.CANDY_PIECE,
  CandyType.CHIPS,
  CandyType.PACKAGING,
  CandyType.DRINK,
];

function calculateMargin(selectedPrice: number, purchasePrice: number) {
  return ((selectedPrice - purchasePrice * 1.21) / selectedPrice) * 100;
}

export const PiecePricesPage = () => {
  return (
    <CandyPage
      fullHeight
      fullWidth
      title="Piece prices"
      skeleton={<PageSkeleton />}
    >
      <InnerCandyOrdersPage />
    </CandyPage>
  );
};

interface PieceRowProps {
  piece: PiecePrice;
  sekToEuro: number;
}

const PieceRow = ({ piece, sekToEuro }: PieceRowProps) => {
  //SEK per grej
  const [noPicture, setNoPicture] = useState(false);
  const [addedToPrint, setAddedToPrint] = useState(false);
  const pricePerPiece = piece.currentPrice / piece.pieces;
  const euroPerPiece = pricePerPiece / sekToEuro;
  //35% margin is suggested
  //21% är moms
  const suggested = (euroPerPiece / 0.65) * 1.21;
  const margin = piece.selectedPrice
    ? calculateMargin(piece.selectedPrice, euroPerPiece)
    : undefined;

  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  //Sätt bakgrundsfärg på:
  // - Ej prissat
  // - LEverantörspris ändrat efter uppdaterat

  const { mutate: addToPrint, isPending: isAddToPrintPending } = useAddToPrint(
    () => {
      showSnackBar("Can not print article", "error");
    }
  );

  return (
    <TableRow
      hover
      sx={{
        ...(piece.priceUpdated && {
          backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.15),
        }),
      }}
    >
      <TableCell
        sx={{
          p: 0.5,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            width: 72,
            aspectRatio: 1,
            borderRadius: 1,
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              display: "block",
            }}
            width={60}
            loading="lazy"
            hidden={noPicture}
            onError={() => setNoPicture(true)}
            onLoad={() => setNoPicture(false)}
            src={`https://storage.googleapis.com/candy-pictures/articles/${piece.articleId}.jpg`}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: 1,
          }}
        >
          <CandyTypeChip candyType={piece.type} />
          <MuiLink
            style={{ marginLeft: "5px" }}
            component={Link}
            to={generatePath(ARTICLE_PAGE_ROUTE, {
              articleId: piece.articleId,
            })}
          >
            {piece.displayName}{" "}
          </MuiLink>
          {piece.provider === ProviderStatus.NOT_AVAILABLE ? (
            <BlockIcon alignmentBaseline="central" color="error" />
          ) : null}
        </Stack>
      </TableCell>
      <TableCell>
        <SetPriceDialog
          piece={piece}
          sekToEuro={sekToEuro}
          suggestedPrice={suggested}
          suggestedMargin={35}
        />
      </TableCell>
      <TableCell>{piece.pieces}</TableCell>
      <TableCell align="center">
        <FormattedPrice value={piece.currentPrice} currency="SEK" />
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          <FormattedPrice value={pricePerPiece} currency="SEK" /> /{" "}
          <FormattedPrice value={euroPerPiece} currency="EUR" />
        </Typography>
      </TableCell>
      <TableCell>{margin ? margin.toFixed(0) + " %" : "-"}</TableCell>
      <TableCell>
        <FormattedDate date={piece.priceSet} pattern="yyyy-MM-dd" />
      </TableCell>
      <TableCell>
        <FormattedDate date={piece.lastOrder} pattern="yyyy-MM-dd" />
      </TableCell>
      <TableCell>
        <IconButton
          color={addedToPrint ? "success" : "primary"}
          disabled={piece.priceSet === undefined || isAddToPrintPending}
          onClick={() => {
            if (addedToPrint) {
              navigate(LABEL_PRINT_PAGE_BASE_ROUTE);
              return;
            }
            addToPrint(piece.articleId);
            setAddedToPrint(true);
          }}
        >
          {isAddToPrintPending ? (
            <CircularProgress size={24} />
          ) : (
            <LocalPrintshopIcon />
          )}
        </IconButton>
        <AddToOrderDialog
          articleId={piece.articleId}
          existingOrder={piece.onOrder}
          displayName={piece.displayName}
          disabled={piece.provider === ProviderStatus.NOT_AVAILABLE}
        />
      </TableCell>
    </TableRow>
  );
};

function boolOrDefault(value: string | null, def: boolean) {
  if (value === null) {
    return def;
  }
  if (value === "true") {
    return true;
  }
  return false;
}

function valueOrDefault(value: string | null, def: string) {
  if (value === null) {
    return def;
  }
  return value;
}

const InnerCandyOrdersPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sekToEuro, setSekToEuro] = useState(1);
  const [typeFilter, setTypeFilter] = useState<CandyType[]>(TYPE_FILTER);
  const { data: pieces } = useSuspenseQuery(piecePriceAPI.fetchFullList());
  useEffect(() => {
    piecePriceAPI.loadExchangeRate().then((r) => setSekToEuro(r.sekToEuro));
  }, []);

  const [pieceFilter, setPieceFilter] = useState(
    valueOrDefault(searchParams.get("pieceFilter"), "")
  );
  const [missingPrices, setMissingPrices] = useState(
    boolOrDefault(searchParams.get("missingPrices"), false)
  );
  const [onlyOrdered, setOnlyOrdered] = useState(
    boolOrDefault(searchParams.get("onlyOrdered"), false)
  );
  const [priceUpdated, setPriceUpdateds] = useState(
    boolOrDefault(searchParams.get("priceUpdated"), false)
  );
  const [order, setOrder] = useState<SortDirection>(SortDirection.DESC);
  const [orderBy, setOrderBy] = useState<
    keyof PiecePrice | "margin" | "piecePrice"
  >("lastOrder");

  const updateSort = (property: keyof PiecePrice | "margin" | "piecePrice") => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  const updateSearchParam = (name: string, value: string) => {
    setSearchParams((searchParams) => {
      searchParams.set(name, value);
      return searchParams;
    });
  };

  //TODO indikera om när man senast spara är äldre än senaste priset, det kan indikera på att man bör uppdatera

  //TODO filter:
  // Endast aktiva

  const items = useMemo(() => {
    if (!pieces) {
      return [];
    }

    return pieces
      .filter((price) => {
        if (typeFilter.length === 0) {
          return true;
        }
        return typeFilter.includes(price.type);
      })
      .filter((price) => {
        if (onlyOrdered) {
          return price.lastOrder;
        }
        return true;
      })
      .filter((price) => {
        if (missingPrices) {
          return price.priceSet === undefined;
        }

        if (priceUpdated) {
          return price.priceUpdated;
        }

        return true;
      })
      .filter((price) => {
        if (!pieceFilter) {
          return true;
        }

        const filter = pieceFilter.toLowerCase();

        return price.displayName.toLowerCase().indexOf(filter) > -1;
      })
      .sort((a, b) => {
        if (orderBy === "margin") {
          const marginA = a.selectedPrice
            ? calculateMargin(
                a.selectedPrice,
                a.currentPrice / a.pieces / sekToEuro
              )
            : undefined;
          const marginB = b.selectedPrice
            ? calculateMargin(
                b.selectedPrice,
                b.currentPrice / b.pieces / sekToEuro
              )
            : undefined;
          return getComparator(order, orderBy)(
            { margin: marginA },
            { margin: marginB }
          );
        }

        if (orderBy === "piecePrice") {
          const priceA = a.currentPrice / a.pieces;
          const priceB = b.currentPrice / b.pieces;
          return getComparator(order, orderBy)(
            { piecePrice: priceA },
            { piecePrice: priceB }
          );
        }

        return getComparator(order, orderBy)(a, b);
      });
  }, [
    pieces,
    typeFilter,
    pieceFilter,
    order,
    orderBy,
    sekToEuro,
    missingPrices,
    priceUpdated,
    onlyOrdered,
  ]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexDirection: "column",
          gap: 2,
          p: 2,
        }}
      >
        <Autocomplete
          multiple
          id="tags-outlined"
          options={TYPE_FILTER}
          onChange={(event, value) => {
            event.preventDefault();
            setTypeFilter(value);
          }}
          value={typeFilter}
          // getOptionLabel={(option) => CandyTypeString(t)[option]}
          renderOption={(props, option, { selected }) =>
            selected ? null : (
              <li {...props} key={option}>
                <CandyTypeChip candyType={option} />
              </li>
            )
          }
          renderTags={(tagValue) => {
            return tagValue.map((option) => (
              <div style={{ paddingRight: "5px" }} key={option}>
                <CandyTypeButton
                  size="medium"
                  candyType={option}
                  onDelete={() => {
                    const updated = typeFilter.filter((t) => t !== option);
                    setTypeFilter(updated);
                  }}
                />
              </div>
            ));
          }}
          sx={{ flex: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Filter candy types")}
              placeholder="Filter types"
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <TextField
            label={t("Filter")}
            sx={{
              width: "50%",
            }}
            value={pieceFilter}
            onChange={(e) => {
              setPieceFilter(e.currentTarget.value);
              updateSearchParam("pieceFilter", e.currentTarget.value);
            }}
          />
          <FormGroup
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              sx={
                {
                  // paddingTop: "2px"
                }
              }
              control={
                <Switch
                  checked={missingPrices}
                  onChange={() => {
                    updateSearchParam("missingPrices", "" + !missingPrices);
                    setMissingPrices(!missingPrices);
                  }}
                />
              }
              label="Missing price"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={priceUpdated}
                  onChange={() => {
                    updateSearchParam("priceUpdated", "" + !priceUpdated);
                    setPriceUpdateds(!priceUpdated);
                  }}
                />
              }
              label="Provider Price updated"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={onlyOrdered}
                  onChange={() => {
                    updateSearchParam("onlyOrdered", "" + !onlyOrdered);
                    setOnlyOrdered(!onlyOrdered);
                  }}
                />
              }
              label="Only Ordered"
            />
          </FormGroup>
        </Box>
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <SortLabel
                label={t("Candy")}
                sortKey="articleId"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Price")}
                sortKey="selectedPrice"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <TableCell>#</TableCell>
              <SortLabel
                label={t("Package Price")}
                sortKey="currentPrice"
                align="right"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label="SEK / EUR"
                sortKey="piecePrice"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Margin")}
                sortKey="margin"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Updated")}
                sortKey="priceSet"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Last order")}
                sortKey="lastOrder"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((price) => (
              <PieceRow
                key={price.articleId}
                piece={price}
                sekToEuro={sekToEuro}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={50} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={70} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
