import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router";
import { AppSidebar, DRAWER_WIDTH } from "../components/layout/AppSidebar";
import { AppBottomBar } from "../components/layout/AppBottomBar";
import { AppTopBar } from "../components/layout/AppTopBar";

export const AppRoot = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const showSidebar = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        ml: showSidebar ? `${DRAWER_WIDTH}px` : 0,
      }}
    >
      <AppSidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        isNarrow={!showSidebar}
      />
      <Box>
        <AppTopBar />
        <Outlet />
      </Box>
      <AppBottomBar
        isMobile={!showSidebar}
        onOpenSidebar={() => setIsSidebarOpen(true)}
      />
    </Box>
  );
};
