import React, { useMemo } from "react";
import { UTCDate } from "../../api/CandyAPI";

interface Props {
  value: string | UTCDate | undefined;
  format?: "date" | "datetime" | "time";
}

export const FormattedDate: React.FC<Props> = ({ value, format = "date" }) => {
  const formattedDate = useMemo(() => {
    if (format === "datetime") {
      return value
        ? new Date(value).toLocaleString("sv-SE", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : "-";
    }

    if (format === "time") {
      return value
        ? new Date(value).toLocaleTimeString("sv-SE", {
            hour: "numeric",
            minute: "numeric",
          })
        : "-";
    }

    return value
      ? new Date(value).toLocaleDateString("sv-SE", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      : "-";
  }, [format, value]);

  return <>{formattedDate}</>;
};
