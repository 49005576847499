import React from "react";
import { DateTime } from "luxon";

interface Props {
  date?: string | Date;
  pattern?: string;
}

const defaultPattern = "yyyy-MM-dd HH:mm";

export class FormattedDate extends React.PureComponent<Props> {
  render() {
    const date = this.props.date;

    let dateTime: DateTime;
    if (!date) {
      return <React.Fragment>-</React.Fragment>;
    } else if (date instanceof Date) {
      dateTime = DateTime.fromJSDate(date);
    } else {
      dateTime = DateTime.fromISO(date);
    }

    return (
      <React.Fragment>
        {dateTime.toFormat(this.props.pattern || defaultPattern)}
      </React.Fragment>
    );
  }
}
