import { ArticleId, UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export type TranslationId = number & { isTranslationId: true };

export enum Namespace {
  DISPLAY_NAME = "DISPLAY_NAME",
  INGREDIENTS = "INGREDIENTS",
}

export interface Translation {
  id: TranslationId;
  articleId: ArticleId;
  language: string;
  namespace: Namespace;
  created: UTCDate;
  createdBy: string;
  value: string;
}

export interface NextTranslation {
  articleId: ArticleId;
}

export interface TranslationCount {
  translated: number;
  total: number;
}

export interface TranslationSuggestion {
  suggestion: string;
}

export const translationApi = {
  loadTranslation: (articleId: ArticleId) => {
    return API.get<Translation[]>(`/translation/${articleId}?language=lv`);
  },

  fetchTranslation: (
    articleId: ArticleId
  ): RequiredQueryOptions<Translation[]> => ({
    queryKey: ["translation", articleId],
    queryFn: () =>
      API.get<Translation[]>(`/translation/${articleId}?language=lv`),
  }),

  loadTranslations: (articleIds: ArticleId[]) => {
    return API.get<Translation[]>(
      `/translation?ids=${articleIds.join(",")}&language=lv`
    );
  },
  updateTranslation: (translation: Translation) => {
    return API.post<Translation>("/translation", translation);
  },

  updateTranslations: async (translations: Translation[]) => {
    return Promise.all(
      translations.map((translation) =>
        translationApi.updateTranslation(translation)
      )
    );
  },

  nextTranslation: (namespace = Namespace.INGREDIENTS) =>
    API.get<NextTranslation>(
      `/translation/next-translation?language=lv&namespace=${namespace}`
    ),

  fetchNextTranslation: (
    namespace = Namespace.INGREDIENTS
  ): RequiredQueryOptions<NextTranslation> => ({
    queryKey: ["translation", "next", namespace],
    queryFn: () =>
      API.get<NextTranslation>(
        `/translation/next-translation?language=lv&namespace=${namespace}`
      ),
  }),

  fetchTranslationCount: (): RequiredQueryOptions<TranslationCount> => ({
    queryKey: ["translation", "count"],
    queryFn: () => API.get<TranslationCount>(`/translation/count`),
  }),

  chatgptSuggest: (
    articleId: ArticleId,
    language: "lv" | "ee" | "lt" | "en"
  ) => {
    return API.post<TranslationSuggestion>(
      `/translation/${articleId}/suggest?language=${language}`
    );
  },
};
