import { useParams } from "react-router";
import { OrderId } from "../api/OrderAPI";

type Param = {
  orderId: OrderId;
};

export function useOrderId() {
  const { orderId } = useParams<Param>();
  return orderId as OrderId;
}
