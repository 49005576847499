import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

export const FeatureCard = ({
  label,
  value,
  icon,
}: {
  label: ReactNode;
  value: string | ReactNode;
  icon?: ReactNode;
}) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: 16 }}
            color="text.secondary"
            fontWeight={700}
            gutterBottom
          >
            {label}
          </Typography>
          {icon}
        </Box>
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{
            fontSize: {
              xs: 20,
              sm: 24,
            },
          }}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const FeatureActionCard = ({
  label,
  value,
  onClick,
  icon,
  disabled,
}: {
  label: ReactNode;
  value: string | ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
}) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 16 }}
              color="text.secondary"
              fontWeight={700}
              gutterBottom
            >
              {label}
            </Typography>
            {icon}
          </Box>
          <Typography
            variant="h5"
            fontWeight={700}
            sx={{
              fontSize: {
                xs: 20,
                sm: 24,
              },
            }}
          >
            {value}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
