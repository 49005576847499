import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArticleId, candyAPI } from "../api/CandyAPI";

export const useSaveArticle = (articleId: ArticleId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: candyAPI.saveArticle,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [articleId] });
    },
  });
};
