import { useMutation, useQuery } from "@tanstack/react-query";
import { authAPI } from "../api/AuthAPI";
import { FunctionComponent, useState } from "react";
import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LOGIN_PAGE_ROUTE } from "../pages/Auth/LoginPage";

export const UserInfo: FunctionComponent<ButtonProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useQuery(authAPI.whoami());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: handleLogout } = useMutation({
    mutationFn: authAPI.logout,
    onSuccess: () => {
      handleClose();
      navigate(LOGIN_PAGE_ROUTE);
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {data && (
        <>
          <Button
            id="user-menu-button"
            aria-controls={open ? "user-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={
              <KeyboardArrowDownIcon
                sx={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.1s",
                }}
              />
            }
            startIcon="👋"
            // variant="text"
            color={open ? "primary" : "inherit"}
            disableElevation
            {...props}
            variant="outlined"
          >
            {data.displayName}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "user-menu-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{t("Logout")}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};
