import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryAPI } from "../../../../../api/InventoryAPI";

type QueryDataType = Awaited<
  ReturnType<
    ReturnType<typeof inventoryAPI.getCurrentReconciliation>["queryFn"]
  >
>;

const queryKey = inventoryAPI.getCurrentReconciliation().queryKey;

export const useReconcileInventoryArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inventoryAPI.reconcileArticleByEan,
    onSuccess: (data) => {
      const { articleId } = data;
      const queryKey = inventoryAPI.getCurrentReconciliation().queryKey;

      queryClient.setQueryData<QueryDataType>(queryKey, (prevData) => {
        if (!prevData) {
          // invalidate the query because seems like we've never fetched the data
          queryClient.invalidateQueries({ queryKey });
          return [data];
        }

        const articleIndex = prevData.findIndex(
          (article) => article.articleId === articleId
        );

        if (articleIndex === -1) {
          return [data, ...prevData];
        }

        return [
          ...prevData.slice(0, articleIndex),
          data,
          ...prevData.slice(articleIndex + 1),
        ];
      });

      return data;
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
