import { Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
import { t } from "i18next";
import { CandyPage } from "../components/layout/CandyPage";
import { MuiButtonLink } from "../components/MuiLink";
import { DASHBOARD_PAGE_ROUTE } from "../pages/Dashboard/DashboardPage";

export const NotFoundPage = () => {
  return (
    <CandyPage fullHeight title="404 Not Found">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          m: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            component={motion.div}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 8,
            }}
          >
            <Typography variant="h2" align="center" gutterBottom>
              😵
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align="center"
            fontWeight={600}
            marginBottom={3}
          >
            {t("Oops! Something went wrong.")}
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            {t("We couldn't find this page.")}
          </Typography>
          <MuiButtonLink
            to={DASHBOARD_PAGE_ROUTE}
            variant="contained"
            color="secondary"
            sx={{
              mt: 4,
            }}
          >
            {t("Go to Dashboard")}
          </MuiButtonLink>
        </Box>
      </Box>
    </CandyPage>
  );
};
