import { AppBar, Toolbar, Typography, Box, IconButton } from "@mui/material";
import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { AnimatePresence, motion } from "framer-motion";

export const APP_TOP_BAR_ID = "app-top-bar";
export const APP_TOP_BAR_ACTIONS_ID = "app-top-bar-actions";

export const AppTopBar: FunctionComponent = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const back = location.pathname.split("/").length > 2;

  return (
    <AppBar
      position="sticky"
      color="inherit"
      variant="elevation"
      elevation={4}
      sx={{
        boxShadow: "none",
        top: 0,
      }}
    >
      <Toolbar
        sx={{
          maxWidth: (theme) => theme.breakpoints.values.lg,
          margin: "0 auto",
          width: "100%",
          px: {
            xs: 2,
            lg: 4,
          },
          display: "flex",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "auto",
            minWidth: 0,
          }}
        >
          <>
            <AnimatePresence>
              {back && (
                <Box
                  component={motion.div}
                  initial={{ x: -20, opacity: 0, width: 0 }}
                  animate={{ x: 0, opacity: 1, width: "auto" }}
                  exit={{ x: -20, opacity: 0, width: 0 }}
                >
                  <IconButton
                    sx={{
                      mr: 1,
                      ml: -1,
                    }}
                    onClick={() =>
                      navigate(
                        -1
                        // location.pathname.split("/").slice(0, -1).join("/")
                      )
                    }
                  >
                    <ChevronLeftRoundedIcon />
                  </IconButton>
                </Box>
              )}
            </AnimatePresence>
            <Box
              id={APP_TOP_BAR_ID}
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                minWidth: 0,
                flexShrink: 1,
              }}
              key={location.pathname}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          </>
        </Box>
        <Box
          id={APP_TOP_BAR_ACTIONS_ID}
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
          key={location.pathname}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
        {/* <Box
          sx={{
            position: "relative",
            borderRadius: (theme) => theme.shape.borderRadius,
            backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
            "&:hover": {
              backgroundColor: (theme) =>
                alpha(theme.palette.common.white, 0.25),
            },
            marginLeft: {
              xs: 0,
              sm: (theme) => theme.spacing(1),
            },
            width: {
              xs: "auto",
              sm: "auto",
            },
          }}
        >
          <Box
            sx={{
              padding: (theme) => theme.spacing(0, 2),
              height: "100%",
              position: "absolute",
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SearchIcon />
          </Box>
          <InputBase
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              onSearchChange?.(e.target.value);
            }}
            inputProps={{ "aria-label": "search" }}
            sx={{
              color: "inherit",
              width: "100%",
              "& .MuiInputBase-input": {
                padding: (theme) => theme.spacing(1, 1, 1, 0),
                paddingLeft: (theme) =>
                  `calc(1em + ${theme.spacing(4)})` as string,
                transition: (theme) => theme.transitions.create("width"),
                width: {
                  xs: "100%",
                  sm: "12ch",
                },
                "&:focus": {
                  width: {
                    xs: "100%",
                    sm: "20ch",
                  },
                },
              },
            }}
          />
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

interface AppTopBarTitleProps {
  title: string;
}

export const AppTopBarTitle: FunctionComponent<AppTopBarTitleProps> = ({
  title,
}) => {
  return (
    <Typography
      variant="h6"
      fontWeight={700}
      noWrap
      sx={{
        fontSize: {
          xs: 16,
          sm: 18,
        },
        flexGrow: 1,
        display: "none",
        "&:last-child": {
          display: "block",
        },
      }}
    >
      {title}
    </Typography>
  );
};
