import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Html5QrcodeResult,
  Html5QrcodeScanType,
  Html5QrcodeScanner,
  Html5Qrcode,
  Html5QrcodeScannerState,
} from "html5-qrcode";
import { Html5QrcodeError } from "html5-qrcode/esm/core";
import { Html5QrcodeScannerConfig } from "html5-qrcode/esm/html5-qrcode-scanner";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useWindowFocus } from "../hooks/useWindowFocus";
import "./Scanner.scss";
import { useRecoilValue } from "recoil";
import { settingsState } from "../state/settingsState";
import { CameraSelect } from "./CameraSelect";

interface ScannerProps {
  onScanSuccess: (
    decodedText: string,
    decodedResult: Html5QrcodeResult
  ) => void;
  onScanError: (errorMessage: string, error: Html5QrcodeError) => void;
  paused?: boolean;
  fps?: number;
  qrbox?: number;
  aspectRatio?: number;
  disableFlip?: boolean;
  verbose?: boolean;
  style?: React.CSSProperties;
  box?: boolean;
  wrapperStyle?: React.CSSProperties;
}

export const Scanner = forwardRef<Html5Qrcode, ScannerProps>(
  (
    {
      onScanSuccess,
      onScanError,
      paused,
      style = {},
      wrapperStyle,
      box = false,
      ...props
    },
    ref
  ) => {
    const scannerRef = useRef<Html5Qrcode>();
    useImperativeHandle(ref, () => scannerRef.current as Html5Qrcode);
    const unmounted = useRef(false);
    const [result, setResult] = useState<Html5QrcodeResult>();
    const shouldReportRef = useRef<boolean>(false);
    const [reset, setReset] = useState(0);
    const focus = useWindowFocus();
    const firstRender = useRef(true);
    const { cameraDeviceId } = useRecoilValue(settingsState);

    useEffect(() => {
      if (result && shouldReportRef.current === true) {
        shouldReportRef.current = false;
        onScanSuccess(result?.decodedText, result);
      }
      setResult(undefined);
    }, [onScanSuccess, result]);

    // DEBUG
    // useEffect(() => {
    //   // scan success every 5000ms
    //   const interval = setInterval(() => {
    //     if (
    //       scannerRef.current?.getState() === Html5QrcodeScannerState.SCANNING
    //     ) {
    //       shouldReportRef.current = true;
    //       setResult({
    //         decodedText: "5704813000701",
    //         result: {
    //           text: "5704813000701",
    //         },
    //       });
    //     }
    //   }, 5000);
    //   return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
      if (paused) {
        if (
          scannerRef.current?.getState() === Html5QrcodeScannerState.SCANNING
        ) {
          scannerRef.current?.pause();
        }
        return;
      }
      if (scannerRef.current?.getState() === Html5QrcodeScannerState.PAUSED) {
        scannerRef.current?.resume();
      }
    }, [paused]);

    useEffect(() => {
      if (typeof scannerRef.current !== "undefined") {
        return;
      }

      scannerRef.current = new Html5Qrcode("scanner");

      const scanner = scannerRef.current;

      const qrWidth = window.innerWidth / 1.75;
      // const qrHeight = qrWidth * 0.6;
      const qrHeight = qrWidth;

      scanner
        .start(
          cameraDeviceId
            ? { deviceId: cameraDeviceId }
            : { facingMode: "environment" },
          {
            fps: 10,
            // TODO: Scale this with screen size perhaps?
            qrbox: box ? { width: qrWidth, height: qrHeight } : undefined,
            disableFlip: false,
            aspectRatio: props.aspectRatio || 2,
          },
          (_, scanResult) => {
            shouldReportRef.current = true;
            setResult(scanResult);
          },
          onScanError
        )
        .then(() => {
          console.debug("Started scanner");
          if (unmounted.current) {
            console.debug("Stopped scanner");
            scannerRef.current?.stop();
            return;
          }
          if (paused) {
            try {
              scannerRef.current?.pause();
            } catch (err) {
              console.debug("Failed pausing scanner");
            }
          }
        })
        .catch((err) => {
          console.error("Failed to start scanner", err);
          scannerRef.current?.stop();
        });
    }, [
      box,
      cameraDeviceId,
      onScanError,
      onScanSuccess,
      paused,
      props.aspectRatio,
      reset,
    ]);

    useEffect(() => {
      return () => {
        unmounted.current = true;
        try {
          scannerRef.current
            ?.stop()
            .then(() => {
              console.log("Stopped scanner");
            })
            .catch(() => {
              console.debug("Failed stopping scanner");
            });
        } catch (err) {
          console.debug("Failed stopping scanner");
        }
      };
    }, []);

    const stop = useCallback(() => {
      try {
        scannerRef.current
          ?.stop()
          .then(() => {
            console.log("Stopped scanner");
          })
          .catch(() => {
            console.debug("Failed stopping scanner");
          });
      } catch (err) {
        console.debug("Failed stopping scanner");
      }
    }, []);

    const resetInstance = useCallback(() => {
      try {
        scannerRef.current
          ?.stop()
          .then(() => {
            console.log("Stopped scanner");
          })
          .catch(() => {
            console.debug("Failed stopping scanner");
          });
      } catch (err) {
        console.debug("Failed stopping scanner");
      } finally {
        scannerRef.current = undefined;
        setReset((prev) => prev + 1);
      }
    }, []);

    useEffect(() => {
      if (firstRender.current) {
        return;
      }
      if (scannerRef.current) {
        resetInstance();
      }
    }, [resetInstance, cameraDeviceId]);

    useEffect(() => {
      if (focus) {
        if (
          !firstRender.current &&
          scannerRef.current?.getState() === Html5QrcodeScannerState.NOT_STARTED
        ) {
          scannerRef.current = undefined;
          setReset((prev) => prev + 1);
        }
      } else {
        stop();
      }
      firstRender.current = false;
    }, [focus, stop]);

    return (
      <div className="scanner-wrapper" style={wrapperStyle}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
            p: 4,
          }}
        >
          <CameraSelect />
          <Typography variant="h6" component="span" align="center">
            Loading camera...
          </Typography>
          <LinearProgress
            color="inherit"
            sx={{
              width: "80%",
              maxWidth: 400,
            }}
          />
          <Button size="medium" color="primary" onClick={resetInstance}>
            Click here to reload if not working
          </Button>
        </Box>
        <div id="scanner" style={{ ...style, pointerEvents: "none" }} />
      </div>
    );
  }
);

export const ScannerVerbose = ({
  onScanSuccess,
  onScanError,
  ...props
}: ScannerProps) => {
  useEffect(() => {
    const config: Html5QrcodeScannerConfig = {
      ...createConfig(props),
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      qrbox: { width: 300, height: 250 },
    };
    const verbose = props.verbose === true;

    if (!onScanSuccess) {
      throw "qrCodeSuccessCallback is required callback.";
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "scanner-verbose",
      config,
      verbose
    );

    html5QrcodeScanner.render(onScanSuccess, onScanError);

    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, [props, onScanError, onScanSuccess]);

  return <div id="scanner-verbose" />;
};

const createConfig = ({
  fps,
  qrbox,
  aspectRatio,
  disableFlip,
}: {
  fps?: number;
  qrbox?: number;
  aspectRatio?: number;
  disableFlip?: boolean;
}): Html5QrcodeScannerConfig => {
  return {
    fps: fps || 10,
    qrbox: qrbox || 250,
    aspectRatio: aspectRatio || 1.0,
    disableFlip: disableFlip || false,
  };
};
