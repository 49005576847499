import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { ArticleId } from "../api/CandyAPI";
import { inventoryAPI } from "../api/InventoryAPI";

export const useArticleInventoryHistory = (articleId: ArticleId) => {
  return useQuery({
    ...inventoryAPI.fetchArticleHistory(articleId),
  });
};

export const useSuspendedArticleInventoryHistory = (articleId: ArticleId) => {
  return useSuspenseQuery({
    ...inventoryAPI.fetchArticleHistory(articleId),
  });
};
