import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { inventoryAPI } from "../../../../api/InventoryAPI";
import { CandyPage } from "../../../../components/layout/CandyPage";
import {
  Grid2,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MuiLink } from "../../../../components/MuiLink";
import { generatePath, useParams } from "react-router";
import { Link } from "react-router-dom";
import { FormattedDate } from "../../../../components/FormattedDate/FormattedDate";
import { ARTICLE_PAGE_ROUTE } from "../../../Article/ArticlePage/ArticlePage";
import { FeatureCard } from "../../../../components/Cards/FeatureCard";

export const INVENTORY_RECONCILIATION_HISTORY_PAGE_ROUTE =
  "/inventory/reconciliation/history/:id";

type PageParams = {
  id: string;
};

export const InventoryReconciliationHistoryPage: React.FunctionComponent =
  () => {
    const { id } = useParams<PageParams>() as PageParams;
    return (
      <CandyPage
        fullHeight
        title={`Reconciliation ${id}`}
        skeleton={<PageSkeleton />}
      >
        <Inner />
      </CandyPage>
    );
  };

const Inner: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams<PageParams>() as PageParams;

  const { data } = useSuspenseQuery(inventoryAPI.getReconciliationReport(id));

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 6,
          }}
        >
          <FeatureCard
            label={t("Date")}
            value={<FormattedDate value={data.date} format="datetime" />}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
          }}
        >
          <FeatureCard label={t("Comitted by user")} value={data.user} />
        </Grid2>
      </Grid2>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Article")}</TableCell>
              <TableCell>{t("Before")}</TableCell>
              <TableCell>{t("After")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.articles.map((row) => (
              <TableRow key={row.articleId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(ARTICLE_PAGE_ROUTE, {
                      articleId: row.articleId,
                    })}
                  >
                    {row.displayName}
                  </MuiLink>
                </TableCell>
                <TableCell>{row.before}</TableCell>
                <TableCell>{row.after}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 6,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2} sx={{ mt: 2 }}>
        <Grid2 size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid2>
        {[...Array(20)].map((_, i) => (
          <Grid2 key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
