import { FunctionComponent } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { RemoteAuth } from "../pages/Auth/components/RemoteAuth";
import { RemoteId } from "../api/AuthAPI";

interface Props {
  onClose: () => void;
  remoteId?: RemoteId;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const AuthSheet: FunctionComponent<Props> = ({ remoteId, onClose }) => {
  const isOpen = typeof remoteId !== "undefined";

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          {isOpen && <InnerAuthSheet {...{ onClose, remoteId }} />}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerAuthSheet: FunctionComponent<Props> = ({ remoteId, onClose }) => {
  return <RemoteAuth remoteId={remoteId} onFinished={onClose} />;
};
