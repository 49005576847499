import { Alert, AlertColor, Box, Snackbar } from "@mui/material";
import React, { createContext, useCallback } from "react";

export type SnackBarContextActions = {
  showSnackBar: (text: string, typeColor?: AlertColor) => void;
};

export const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarData {
  id: number;
  message: string;
  typeColor: AlertColor;
}

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

export const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [snackbars, setSnackbars] = React.useState<SnackBarData[]>([]);

  const showSnackBar = useCallback(
    (text: string, color: AlertColor = "info") => {
      const newSnackbar = {
        id: new Date().getTime(), // Unique ID for the snackbar
        message: text,
        typeColor: color,
      };
      setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    },
    []
  );

  const handleClose = useCallback((id: number) => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snackbar) => snackbar.id !== id)
    );
  }, []);

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {snackbars.map((snackbar) => (
          <Snackbar
            key={snackbar.id}
            open
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => handleClose(snackbar.id)}
          >
            <Alert
              variant="filled"
              onClose={() => handleClose(snackbar.id)}
              severity={snackbar.typeColor}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
      {children}
    </SnackBarContext.Provider>
  );
};
