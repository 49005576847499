import { Box, Button, Paper, Skeleton, Typography } from "@mui/material";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  Suspense,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Helmet } from "./Helmet";
import { ErrorBoundary } from "@highlight-run/react";

interface Props {
  fullHeight?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  title: string;
  skeleton?: ReactNode;
}

export const CandyPage: FunctionComponent<PropsWithChildren<Props>> = ({
  fullHeight = false,
  fullScreen = false,
  fullWidth = false,
  title,
  skeleton,
  children,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Helmet title={title} />
      <Box
        sx={{
          px: {
            xs: fullScreen || fullWidth ? 0 : 1,
            md: fullScreen || fullWidth ? 0 : 2,
            lg: 2,
          },
          pt: {
            xs: fullScreen ? 0 : 1,
            md: fullScreen ? 0 : 2,
          },
          position: fullHeight ? "relative" : "static",
          maxWidth: (theme) =>
            fullWidth ? "100%" : theme.breakpoints.values.lg,
          width: "100%",
          margin: "0 auto",
          ...(fullHeight
            ? {
                height: {
                  xs: "calc(100vh - calc(56px * 2))",
                  sm: "calc(100vh - calc(64px * 2))",
                },
                // overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }
            : {
                mb: {
                  xs: "calc(56px + 34px)",
                  sm: "calc(64px + 16px)",
                },
                pb: {
                  xs: "calc(56px + 34px)",
                  sm: "calc(64px + 16px)",
                },
              }),
        }}
      >
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <>
              <ErrorBoundary
                key={location.pathname}
                onReset={reset}
                showDialog={false}
                fallback={({ error, resetError }) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80vh",
                        p: 2,
                      }}
                    >
                      <Typography variant="h5" gutterBottom>
                        {t("Oops, something went wrong")}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {t("Couldn't load the page, try again later")}
                      </Typography>
                      {/* if development */}
                      {import.meta.env.DEV && (
                        <Paper sx={{ p: 2 }}>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                              margin: 0,
                              maxHeight: "40vh",
                              overflowY: "auto",
                            }}
                          >
                            {error.stack}
                          </pre>
                        </Paper>
                      )}

                      <Button
                        onClick={resetError}
                        variant="outlined"
                        size="large"
                        sx={{ mt: 2 }}
                      >
                        {t("Try again")}
                      </Button>
                    </Box>
                  </>
                )}
              >
                <Suspense
                  fallback={
                    <Box sx={{ p: 2 }}>
                      {skeleton || (
                        <>
                          <h1>
                            <Skeleton variant="text" />
                          </h1>
                          <div>
                            <Skeleton variant="rounded" />
                            <Skeleton variant="rounded" height={120} />
                          </div>
                        </>
                      )}
                    </Box>
                  }
                >
                  {children}
                </Suspense>
              </ErrorBoundary>
            </>
          )}
        </QueryErrorResetBoundary>
      </Box>
    </>
  );
};
