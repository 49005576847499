import { CandyType } from "../api/CandyAPI";
import { FormattedPrice } from "./FormattedPrice/FormattedPrice";

interface Props {
  price?: number;
  candyType?: CandyType;
}

export enum CandyUnit {
  KILO = "kg",
  PIECE = "",
}

export const getCandyUnit = (candyType?: CandyType): CandyUnit =>
  typeof candyType === "undefined" ||
  candyType === CandyType.CANDY_PIECE ||
  candyType === CandyType.CHIPS
    ? CandyUnit.PIECE
    : CandyUnit.KILO;

export const UnitPrice = ({ price = 0, candyType }: Props) => {
  const candyUnit = getCandyUnit(candyType);

  if (candyUnit) {
    return (
      <>
        <FormattedPrice value={price} currency="SEK" /> / {candyUnit}
      </>
    );
  }

  return <FormattedPrice value={price} currency="SEK" />;
};
