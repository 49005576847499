import { useSetRecoilState } from "recoil";
import { removeStockState } from "../state/removeStockState";
import { ArticleId } from "../../../../api/CandyAPI";

export const useRemoveFromRemoveStockList = () => {
  const setState = useSetRecoilState(removeStockState);

  return (articleId: ArticleId) => {
    setState((oldState) => {
      const quantity = oldState.items.find(
        (item) => item.articleId === articleId
      )?.quantity;

      if (typeof quantity === "undefined") {
        return oldState;
      }

      const newList =
        quantity < -1
          ? oldState.items.map((item) =>
              item.articleId === articleId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            )
          : oldState.items.filter((item) => item.articleId !== articleId);

      return {
        ...oldState,
        items: newList,
      };
    });
  };
};
