import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import { ArticleId, CandyType, candyAPI } from "../api/CandyAPI";
import { DateTime } from "luxon";
import { roundToTwoDecimals } from "../utils";
import { useTranslation } from "react-i18next";
import { LineChart } from "@mui/x-charts";
import { formattedPrice } from "./FormattedPrice/FormattedPrice";
import { useQuery } from "@tanstack/react-query";

interface Props {
  articleId: ArticleId;
  candyType?: CandyType;
  packageSize?: number;
}

export const PriceChart: FunctionComponent<Props> = ({
  articleId,
  packageSize = 1,
}) => {
  const theme = useTheme();
  const [display, setDisplay] = useState<"package" | "unit">("unit");

  const [toDate] = useState<DateTime>(DateTime.now());
  const [fromDate] = useState<DateTime>(DateTime.now().minus({ months: 6 }));

  const { t } = useTranslation();

  const { data } = useQuery(
    candyAPI.fetchArticlePriceHistory(
      articleId,
      fromDate.toISODate() as string,
      toDate.toISODate() as string
    )
  );

  const computedData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    if (display === "package") {
      return data.map(({ created, ourPrice }) => ({
        date: new Date(created.split("T")[0]),
        price: ourPrice,
      }));
    }

    // kg price
    return data.map(({ created, ourPrice }) => ({
      date: new Date(created.split("T")[0]),
      price: roundToTwoDecimals(Number(ourPrice) / packageSize),
    }));
  }, [data, display, packageSize]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Tabs
          sx={{ flex: 0 }}
          value={display}
          variant="fullWidth"
          onChange={(_, newValue) => {
            setDisplay(newValue);
          }}
        >
          <Tab label={t("Unit price")} value="unit" />
          <Tab label={t("Package price")} value="package" />
        </Tabs>
        <Box sx={{ flex: 1, height: "100%" }}>
          <LineChart
            dataset={computedData}
            height={400}
            xAxis={[
              {
                id: "Months",
                dataKey: "date",
                scaleType: "time",
                // get month in the format "Jan", "Feb", etc.
                // valueFormatter: (date) =>
                //   new Date(date).toLocaleString("default", { month: "short" }),
              },
            ]}
            series={[
              {
                id: "Price",
                dataKey: "price",
                type: "line",
                color: theme.palette.primary.main,
                baseline: 0,
                showMark: false,
                valueFormatter: (price) => formattedPrice(price || 0, "SEK"),
              },
            ]}
          />
        </Box>
      </Box>
    </>
  );
};
