import { useState } from "react";

export const useWindowFocus = () => {
  const [hasFocus, setHasFocus] = useState(true);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      setHasFocus(true);
    } else {
      setHasFocus(false);
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  return hasFocus;
};
