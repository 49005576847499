import { AtomEffect, atom, selector, selectorFamily } from "recoil";
import { ArticleId, CandyType } from "../api/CandyAPI";

const localStorageEffect =
  (key: string): AtomEffect<PickList> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: PickList, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const updateTimestamps =
  (): AtomEffect<PickList> =>
  ({ setSelf, onSet }) => {
    onSet((newValue: PickList) => {
      if (newValue.items.length === 0) {
        setSelf({
          ...newValue,
          lastUpdated: undefined,
        });
      }
      setSelf({
        ...newValue,
        lastUpdated: new Date().toISOString(),
      });
    });
  };

export interface PickItem {
  articleId: ArticleId;
  ean: string;
  displayName: string;
  candyType?: CandyType;
  quantity: number;
  updated?: number;
}

interface PickList {
  lastUpdated: string | undefined;
  items: PickItem[];
}

const defaultState = (): PickList => ({
  lastUpdated: undefined,
  items: [],
});

const STATE_VERSION = "1";
const STATE_KEY = `pick-state-${STATE_VERSION}`;

export const pickState = atom<PickList>({
  key: STATE_KEY,
  default:
    (localStorage.getItem(STATE_KEY) as unknown as PickList) || defaultState(),
  effects: [updateTimestamps(), localStorageEffect(STATE_KEY)],
});

export const pickArticleSelector = selectorFamily({
  key: "pickArticleSelector",
  get:
    (articleId: ArticleId | undefined) =>
    ({ get }) => {
      return get(pickState).items.find((item) => item.articleId === articleId);
    },
});

export const pickListCountSelector = selector({
  key: "pickListCountSelector",
  get: ({ get }) => {
    return get(pickState).items.reduce((acc, item) => acc + item.quantity, 0);
  },
});
