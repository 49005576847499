import { useRecoilValue } from "recoil";
import { PickItem, pickState } from "../state/pickState";
import { ArticleId } from "../api/CandyAPI";
import { CandyPage } from "../components/layout/CandyPage";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { CandyPickSheet } from "../components/CandyPickSheet";
import { useRemoveFromPickList } from "../hooks/useRemoveFromPickList";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useAddToPickList } from "../hooks/useAddToPickList";
import { useTranslation } from "react-i18next";
import { AppTopBarActions } from "../components/layout/AppTopBarActions";
import { ConfirmButton } from "../components/ConfirmButton";
import { useResetPickList } from "../hooks/useResetPickList";

export const CANDY_PICK_PAGE_ROUTE = "/pick";

export const CandyPickPage: React.FunctionComponent = () => {
  const { items } = useRecoilValue(pickState);
  const reset = useResetPickList();
  const [activeArticleId, setActiveArticleId] = useState<ArticleId>();
  const removeFromPickList = useRemoveFromPickList();
  const addToPickList = useAddToPickList();
  const { t } = useTranslation();

  const activeItem = useMemo(
    () => items.find((i) => i.articleId === activeArticleId),
    [items, activeArticleId]
  );

  const onPick = (candy: PickItem) => {
    setActiveArticleId(candy.articleId);
  };

  return (
    <CandyPage fullHeight fullScreen title="Pick list">
      <AppTopBarActions>
        {items.length > 0 && (
          <ConfirmButton
            color="inherit"
            confirmText={t(
              "Are you sure you want to clear the pick list of all its items?"
            )}
            onConfirm={reset}
          >
            {t("Clear all")}
          </ConfirmButton>
        )}
      </AppTopBarActions>
      <CandyPickSheet
        item={activeItem}
        onClose={() => setActiveArticleId(undefined)}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Name")}</TableCell>
              <TableCell align="center">{t("Quantity")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Typography variant="body1">
                    {t("Nothing to pick, well done")} 👏
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {items.map((candy) => (
              <TableRow key={candy.articleId} hover>
                <TableCell>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => onPick(candy)}
                  >
                    {t("Pick")}
                  </Button>
                </TableCell>
                <TableCell onClick={() => onPick(candy)}>
                  {candy.displayName}
                </TableCell>
                <TableCell
                  align="center"
                  // onClick={() => onPick(candy)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={() => removeFromPickList(candy.articleId)}
                    color="secondary"
                    size="large"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Box>
                    <strong>{candy.quantity}</strong>
                  </Box>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      addToPickList(
                        {
                          ean: candy.ean,
                          articleId: candy.articleId,
                          displayName: candy.displayName,
                          candyType: candy.candyType,
                        },
                        1
                      );
                    }}
                    color="secondary"
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CandyPage>
  );
};
