import { ArticleId, CandyType, UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export type OrderId = string & { isOrderId: true };

export interface OrderItem {
  articleId: ArticleId;
  created: UTCDate;
  orderId?: OrderId; // När den här finns så har man skickat ordern till ERT
  count: number;
  itemPrice: number;
  createdBy: string;
  displayName: string;
  providerStock: number;
  candyType: CandyType;
  packageSize: number;
}

export interface Order {
  orderId: OrderId;
  externalOrderReference: string;
  itemCount: number;
  orderWeight: number;
  ordered: UTCDate;
  delivered?: UTCDate;
  confirmed?: UTCDate;
  shipped?: UTCDate;
  totalPrice: number;
  shippingCost: number;
}

export interface OrderedItem {
  id: number;
  articleId: ArticleId;
  confirmed: number;
  delivered: number;
  ordered: number;
  price: number;
  displayName: string;
  updated?: UTCDate;
}

export interface ReconciliationEntry {
  id: number;
  ean: string;
  articleId: ArticleId;
  confirmed: number;
  delivered: number;
  ordered: number;
  displayName: string;
  updated?: UTCDate;
}

interface ReconcileArticleParamsWithEan {
  orderId: OrderId;
  ean: string;
  articleId?: never;
}

interface ReconcileArticleParamsWithArticleId {
  orderId: OrderId;
  articleId: ArticleId;
  ean?: never;
}

interface PendingOrderSummary {
  totalPrice: number;
  totalArticles: number;
  totalBoxes: number;
  totalWeight: number;
}

type ReconcileArticleParams =
  | ReconcileArticleParamsWithEan
  | ReconcileArticleParamsWithArticleId;

const QUERY_KEY = "orders";

interface ReconciliationResponse {
  item: ReconciliationEntry;
  orderItems: ReconciliationEntry[];
}

export const orderAPI = {
  QUERY_KEY,
  fetchOrders: (): RequiredQueryOptions<Order[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<Order[]>(`/orders`),
  }),

  fetchOrder: (orderId: string): RequiredQueryOptions<Order> => ({
    queryKey: [QUERY_KEY, orderId],
    queryFn: () => API.get<Order>(`/orders/${orderId}`),
  }),

  fetchOrderedItems: (
    orderId: string
  ): RequiredQueryOptions<OrderedItem[]> => ({
    queryKey: [QUERY_KEY, orderId, "items"],
    queryFn: () => API.get<OrderedItem[]>(`/orders/${orderId}/items`),
  }),

  fetchNextOrder: (): RequiredQueryOptions<OrderItem[]> => ({
    queryKey: [QUERY_KEY, "pending"],
    queryFn: () => API.get<OrderItem[]>(`/orders/pending`),
  }),

  addToNextOrder: ({
    articleId,
    count = 1,
  }: {
    articleId: ArticleId;
    count: number;
  }) =>
    API.post<OrderItem>(`/orders/pending/${articleId}`, {
      count,
    }),

  deleteFromNextOrder: (articleId: ArticleId) =>
    API.delete(`/orders/pending/${articleId}`),

  loadPendingOrder: (articleId: ArticleId) =>
    API.get<OrderItem>(`/orders/pending/${articleId}`),

  fetchPendingOrder: (
    articleId: ArticleId
  ): RequiredQueryOptions<OrderItem> => ({
    queryKey: [QUERY_KEY, "pending", articleId],
    queryFn: () => API.get<OrderItem>(`/orders/pending/${articleId}`),
  }),

  fetchOrderReconciliation: (
    orderId: OrderId
  ): RequiredQueryOptions<ReconciliationEntry[]> => ({
    queryKey: [QUERY_KEY, orderId, "reconciliation"],
    queryFn: () =>
      API.get<ReconciliationEntry[]>(`/order/reconciliation/${orderId}`),
  }),

  fetchPendingOrderOverview: (): RequiredQueryOptions<PendingOrderSummary> => ({
    queryKey: [QUERY_KEY, "pending", "summary"],
    queryFn: () => API.get<PendingOrderSummary>(`/orders/pending/summary`),
  }),

  reconcileArticle: ({
    orderId,
    articleId,
    ean,
  }: ReconcileArticleParams): Promise<{
    item: ReconciliationEntry;
    orderItems: ReconciliationEntry[];
  }> => {
    if (typeof articleId !== "undefined") {
      return orderAPI.reconcileArticleById({ orderId, articleId });
    } else {
      return orderAPI.reconcileArticleByEan({ orderId, ean });
    }
  },

  reconcileArticleByEan: ({ orderId, ean }: ReconcileArticleParamsWithEan) =>
    API.post<ReconciliationResponse>(
      `/order/reconciliation/${orderId}?ean=${ean}`
    ),
  // {
  //   let changed = false;
  //   let item = MOCKED_RECONCILIATION.find(
  //     (entry) => !changed && entry.delivered !== entry.ordered
  //   );
  //   MOCKED_RECONCILIATION = MOCKED_RECONCILIATION.map((entry) => {
  //     if (!changed && entry.delivered !== entry.ordered) {
  //       changed = true;
  //       item = {
  //         ...entry,
  //         delivered: entry.delivered + 1,
  //       };
  //       return item;
  //     }
  //     return entry;
  //   });

  //   if (!item) return Promise.reject("No more items to reconcile");

  //   return Promise.resolve({
  //     item,
  //     orderItems: MOCKED_RECONCILIATION,
  //   });
  // },

  reconcileArticleById: ({
    orderId,
    articleId,
  }: ReconcileArticleParamsWithArticleId) =>
    API.post<ReconciliationResponse>(
      `/order/reconciliation/${orderId}?articleId=${articleId}`
    ),
  //   {
  //   let changed = false;
  //   let item = MOCKED_RECONCILIATION.find(
  //     (entry) => !changed && entry.delivered !== entry.ordered
  //   );
  //   MOCKED_RECONCILIATION = MOCKED_RECONCILIATION.map((entry) => {
  //     if (!changed && entry.delivered !== entry.ordered) {
  //       changed = true;
  //       item = {
  //         ...entry,
  //         delivered: entry.delivered + 1,
  //       };
  //       return item;
  //     }
  //     return entry;
  //   });

  //   if (!item) return Promise.reject("No more items to reconcile");

  //   return Promise.resolve({
  //     item,
  //     orderItems: MOCKED_RECONCILIATION,
  //   });
  // },

  markOrderAsReconciled: (orderId: OrderId) =>
    API.post(`/order/reconciliation/${orderId}/done`),
};
