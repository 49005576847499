import { CandyPage } from "../../components/layout/CandyPage";
import { useParams } from "react-router";
import { RemoteAuth } from "./components/RemoteAuth";
import { RemoteId } from "../../api/AuthAPI";

export const AUTHENTICATE_PAGE_ROUTE = "/authenticate/:remoteId";

export const AuthenticatePage = () => {
  const { remoteId } = useParams<{ remoteId: RemoteId }>();

  return (
    <CandyPage fullHeight title="Authenticate login">
      <RemoteAuth remoteId={remoteId} />
    </CandyPage>
  );
};
