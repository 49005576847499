import { useSetRecoilState } from "recoil";
import { ArticleId } from "../api/CandyAPI";
import { stockState } from "../state/stockState";

export const useRemoveFromStockList = () => {
  const setPickState = useSetRecoilState(stockState);

  return (articleId: ArticleId) => {
    setPickState((oldState) => {
      const quantity = oldState.items.find(
        (item) => item.articleId === articleId
      )?.quantity;

      if (typeof quantity === "undefined") {
        return oldState;
      }

      const newPickList =
        quantity > 1
          ? oldState.items.map((item) =>
              item.articleId === articleId
                ? { ...item, quantity: item.quantity - 1 }
                : item
            )
          : oldState.items.filter((item) => item.articleId !== articleId);

      return {
        ...oldState,
        items: newPickList,
      };
    });
  };
};
