import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import {
  Button,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { ArticleId } from "../../api/CandyAPI";
import { ProductInventory, webshopAPI } from "../../api/WebshopAPI";
import { CandyPage } from "../../components/layout/CandyPage";
import { MuiLink } from "../../components/MuiLink";
import { SortLabel, getComparator } from "../../components/table/SortLabel";
import { useSnackBar } from "../../hooks/useSnackbar";
import { ARTICLE_PAGE_ROUTE } from "../Article/ArticlePage/ArticlePage";
import { useDisableProduct } from "./hooks/useDisableProduct";
import { useEnableProduct } from "./hooks/useEnableProduct";
import { SortDirection } from "../../api/Page";

export const WEBSHOP_PRODUCTS_PAGE_ROUTE = "/webshop/products";

export const WebshopProductsPage: React.FunctionComponent = () => {
  return (
    <CandyPage
      fullHeight
      fullScreen
      title="Webshop - Products"
      skeleton={<PageSkeleton />}
    >
      <InnerCandyInventoryPage />
    </CandyPage>
  );
};

const InnerCandyInventoryPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { showSnackBar } = useSnackBar();

  const [order, setOrder] = useState<SortDirection>(SortDirection.DESC);
  const [orderBy, setOrderBy] = useState<keyof ProductInventory>("articleId");

  const { data: products } = useSuspenseQuery(
    webshopAPI.fetchProductInventory()
  );

  const updateSort = (property: keyof ProductInventory) => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  const { mutate: disableProduct } = useDisableProduct();
  const { mutate: enableProduct } = useEnableProduct();

  //TODO: make component?
  const providerStockPrint = (providerStock: number) => {
    if (providerStock < 0) {
      return "0";
    }
    if (providerStock > 100) {
      return "> 100";
    }
    return providerStock;
  };

  const handleEnableProduct = (articleId: ArticleId) => {
    enableProduct(articleId, {
      onSuccess: () => {
        showSnackBar(t("Successfully enabled product"), "success");
      },
      onError: () => {
        showSnackBar(t("Error enabling product"), "error");
      },
    });
  };

  const handleDisableProduct = (articleId: ArticleId) => {
    disableProduct(articleId, {
      onSuccess: () => {
        showSnackBar(t("Successfully disabled product"), "success");
      },
      onError: () => {
        showSnackBar(t("Error disabling product"), "error");
      },
    });
  };

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortLabel
                label={t("Article")}
                sortKey="displayName"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Web Orders")}
                sortKey="orders"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Inventory")}
                sortKey="currentStock"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
              <SortLabel
                label={t("Provider")}
                sortKey="providerStock"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
              <SortLabel
                label={t("In stock")}
                sortKey="inStock"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.sort(getComparator(order, orderBy)).map((product) => (
              <TableRow key={product.articleId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(ARTICLE_PAGE_ROUTE, {
                      articleId: product.articleId,
                    })}
                  >
                    {product.displayName}
                  </MuiLink>
                </TableCell>
                <TableCell>{product.orders}</TableCell>
                <TableCell align="right">{product.currentStock}</TableCell>
                <TableCell align="right">
                  {providerStockPrint(product.providerStock)}
                </TableCell>
                <TableCell align="right">
                  {product.inStock ? (
                    <Button
                      variant="outlined"
                      startIcon={<RemoveShoppingCartIcon />}
                      onClick={() => {
                        handleDisableProduct(product.articleId);
                      }}
                    >
                      {t("Disable")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      startIcon={<AddShoppingCartIcon />}
                      onClick={() => {
                        handleEnableProduct(product.articleId);
                      }}
                    >
                      {t("Enable")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Skeleton variant="rounded" height={90} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
