import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArticleId } from "../api/CandyAPI";
import { InventoryItem, inventoryAPI } from "../api/InventoryAPI";

type QueryDataType =
  | Awaited<
      ReturnType<ReturnType<typeof inventoryAPI.fetchInventory>["queryFn"]>
    >
  | undefined;

export const useUpdateInventory = (articleId: ArticleId) => {
  const queryClient = useQueryClient();

  const queryKey = inventoryAPI.fetchInventory(articleId).queryKey;

  return useMutation({
    mutationFn: (count: number) => inventoryAPI.updateInventory(articleId, count),
    onMutate: async (count) => {
      await queryClient.cancelQueries({ queryKey });
      const previousItems = queryClient.getQueryData<InventoryItem>(queryKey);
      queryClient.setQueryData<QueryDataType>(queryKey, (old) => {
        if (!old) {
          return undefined;
        }

        return {
          ...old,
          currentStock: count,
        };
      });

      return { previousItems };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: [inventoryAPI.QUERY_KEY, "list"],
        exact: true,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousItems);
    },
  });
};
