import { useCallback } from "react";
import { useNavigate } from "react-router";
import { ConfirmFab } from "../../../../components/ConfirmButton";
import { ORDER_LIST_PAGE_ROUTE } from "../../OrderListPage/OrderListPage";
import { useReconcileOrder } from "../hooks/useReconcileOrder";
import { OrderId } from "../../../../api/OrderAPI";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../../../hooks/useSnackbar";

interface Props {
  orderId: OrderId;
}
export const MarkOrderDoneButton = ({ orderId }: Props) => {
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const { mutateAsync: reconcileOrder, isPending } = useReconcileOrder();

  const doReconcileOrder = useCallback(async () => {
    return reconcileOrder(orderId, {
      onSuccess: () => {
        showSnackBar(t("Order marked as complete"), "success");
        navigate(ORDER_LIST_PAGE_ROUTE);
      },
      onError: () => {
        showSnackBar(t("Failed to mark order as complete"), "error");
      },
    });
  }, [navigate, orderId, reconcileOrder, showSnackBar, t]);

  return (
    <ConfirmFab
      variant="extended"
      size="small"
      sx={{
        fontSize: "0.75rem",
        px: 2,
      }}
      color="success"
      confirmText={t(
        "Are you sure you want to mark the order as complete? You won't be able to undo this action."
      )}
      onConfirm={() => doReconcileOrder()}
      disabled={isPending}
    >
      {t("Mark order as complete")}
    </ConfirmFab>
  );
};
