import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { ArticleId } from "../../../api/CandyAPI";
import { OrderItem, orderAPI } from "../../../api/OrderAPI";
import { CandyPage } from "../../../components/layout/CandyPage";
import { MuiLink } from "../../../components/MuiLink";
import { getCandyUnit } from "../../../components/UnitPrice";
import { useAddToOrder } from "../../../hooks/useAddToOrder";
import { useRemoveFromOrder } from "../../../hooks/useRemoveFromOrder";
import { useSnackBar } from "../../../hooks/useSnackbar";
import { ARTICLE_PAGE_ROUTE } from "../../Article/ArticlePage/ArticlePage";
import { SortDirection } from "../../../api/Page";
import { SortLabel, getComparator } from "../../../components/table/SortLabel";
import { BACKEND } from "../../../api/network/API";
import { FeatureCard } from "../../../components/Cards/FeatureCard";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { AppTopBarActions } from "../../../components/layout/AppTopBarActions";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";

export const ORDER_NEXT_PAGE_ROUTE = "/order/next";

export const OrderNextPage = () => {
  return (
    <CandyPage fullHeight title="Next order" skeleton={<PageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

const Inner = () => {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const [{ data: items }, { data: overview }] = useSuspenseQueries({
    queries: [orderAPI.fetchNextOrder(), orderAPI.fetchPendingOrderOverview()],
  });

  const { mutate: addToOrder } = useAddToOrder();
  const { mutate: removeFromOrder } = useRemoveFromOrder();

  const [order, setOrder] = useState<SortDirection>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState<keyof OrderItem | "price">(
    "articleId"
  );

  const updateSort = (property: keyof OrderItem | "price") => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  const sortedItems = useMemo(() => {
    return items.sort((a, b) => {
      if (orderBy === "price") {
        return getComparator(order, orderBy)(
          { price: a.itemPrice * a.count },
          { price: b.itemPrice * b.count }
        );
      }
      return getComparator(order, orderBy)(a, b);
    });
  }, [items, order, orderBy]);

  const updateArticle = useCallback(
    (articleId: ArticleId, count: number) => {
      if (count === 0) {
        return removeFromOrder(articleId, {
          onSuccess: () => {
            showSnackBar(
              t("Removed {{article}} from next order!", { article: articleId }),
              "success"
            );
          },
          onError: () => {
            showSnackBar(
              t("Failed to remove {{article}}", { article: articleId }),
              "error"
            );
          },
        });
      }

      addToOrder(
        {
          articleId,
          count,
        },
        {
          onSuccess: () => {
            showSnackBar(
              t("Updated quantity of {{article}} on next order!", {
                article: articleId,
              }),
              "info"
            );
          },
          onError: () => {
            showSnackBar(
              t("Failed to update quantity of {{article}}", {
                article: articleId,
              }),
              "error"
            );
          },
        }
      );
    },
    [addToOrder, removeFromOrder, showSnackBar, t]
  );

  return (
    <>
      <AppTopBarActions>
        <Button
          variant="text"
          href={BACKEND + "/orders/pending/export"}
          download
          color="inherit"
          startIcon={<FileDownloadRoundedIcon />}
        >
          {t("Download")}
        </Button>
      </AppTopBarActions>
      {true && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid
            size={{
              xs: 6,
              sm: 4,
            }}
          >
            <FeatureCard
              label={t("Cost")}
              value={
                <FormattedPrice value={overview.totalPrice} currency="SEK" />
              }
            />
          </Grid>
          <Grid
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            size={{
              xs: 6,
              sm: 4,
            }}
          >
            <FeatureCard
              label={t("Weight")}
              value={overview.totalWeight + " kg"}
            />
          </Grid>
          <Grid
            size={{
              xs: 6,
              sm: 2,
            }}
          >
            <FeatureCard label={t("Articles")} value={overview.totalArticles} />
          </Grid>
          <Grid
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
            size={{
              xs: 6,
              sm: 2,
            }}
          >
            <FeatureCard label={t("Boxes")} value={overview.totalBoxes} />
          </Grid>
        </Grid>
      )}
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                width={4}
                sx={{
                  p: 0,
                }}
              />
              <TableCell />
              <SortLabel
                label={t("Article")}
                sortKey="displayName"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Price")}
                sortKey="price"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <TableCell align="right">{t("Weight")}</TableCell>
              <SortLabel
                label={t("Quantity")}
                sortKey="count"
                direction={order}
                orderBy={orderBy}
                align="right"
                updateSort={updateSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((candy) => (
              <NextOrderRow
                key={candy.articleId}
                candy={candy}
                updateArticle={updateArticle}
                removeArticle={(articleId) => updateArticle(articleId, 0)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={60} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

interface NextOrderProp {
  candy: OrderItem;
  updateArticle: (articleId: ArticleId, count: number) => void;
  removeArticle: (articleId: ArticleId) => void;
}

const NextOrderRow = ({
  candy,
  updateArticle,
  removeArticle,
}: NextOrderProp) => {
  const [noPicture, setNoPicture] = useState(false);

  return (
    <TableRow key={candy.articleId}>
      <TableCell
        sx={{
          p: {
            xs: 0.5,
            sm: 1,
          },
          backgroundColor: `${candy.providerStock > 0 ? "green" : "orange"}`,
        }}
      />
      <TableCell
        padding="none"
        sx={{
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          sx={{ display: "block" }}
          width={80}
          loading="lazy"
          hidden={noPicture}
          onError={() => setNoPicture(true)}
          onLoad={() => setNoPicture(false)}
          src={`https://storage.googleapis.com/candy-pictures/articles/${candy.articleId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(ARTICLE_PAGE_ROUTE, {
            articleId: candy.articleId,
          })}
        >
          {candy.displayName}
        </MuiLink>
      </TableCell>
      <TableCell>
        <FormattedPrice value={candy.itemPrice * candy.count} currency="SEK" />
      </TableCell>
      <TableCell align="right">
        {(candy.packageSize * candy.count).toLocaleString("sv-SE", {
          maximumFractionDigits: 1,
        })}{" "}
        {getCandyUnit(candy.candyType)}
      </TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => updateArticle(candy.articleId, candy.count - 1)}
            color="secondary"
          >
            <RemoveIcon />
          </IconButton>
          <Box>{candy.count}</Box>
          <IconButton
            onClick={() => updateArticle(candy.articleId, candy.count + 1)}
            color="secondary"
          >
            <AddIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => removeArticle(candy.articleId)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
