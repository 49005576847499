import {
  Autocomplete,
  Backdrop,
  Badge,
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { ArticleFilter, CandyType } from "../../../../api/CandyAPI";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  CandyTypeChip,
  CandyTypeButton,
} from "../../../../components/CandyTypeChip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

interface Props {
  onChange: (filter: ArticleFilter) => void;
  filter: ArticleFilter;
}

export const ArticleFilters: FunctionComponent<Props> = ({
  onChange,
  filter,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const activeFilterCount = Object.values(filter).filter((v) => !!v).length;

  return (
    <>
      <Badge badgeContent={activeFilterCount} color="primary">
        <Button
          aria-describedby={id}
          variant="text"
          color={activeFilterCount > 0 ? "primary" : "inherit"}
          onClick={handleClick}
          sx={{ zIndex: (theme) => (open ? theme.zIndex.drawer + 2 : "auto") }}
          endIcon={<FilterListRoundedIcon />}
        >
          {t("Filters")}
        </Button>
      </Badge>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Popover
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                width: (theme) => ({
                  xs: "100%",
                  sm: theme.breakpoints.values.sm / 1.5,
                }),
              },
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  color: "text.secondary",
                  lineHeight: 1,
                }}
              >
                {t("Filters")}
              </Typography>
            </Box>
            <Inner {...{ onChange, filter }} />
          </Box>
        </Popover>
      </Backdrop>
    </>
  );
};

const Inner: FunctionComponent<Props> = ({ onChange, filter }) => {
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="type-filter"
            options={TYPE_FILTER}
            onChange={(event, value) => {
              event.preventDefault();

              onChange({
                ...filter,
                types: value,
              });
            }}
            value={filter.types ? filter.types : []}
            // getOptionLabel={(option) => CandyTypeString(t)[option]}
            renderOption={(props, option, { selected }) =>
              selected ? null : (
                <li {...props} key={option}>
                  <CandyTypeChip candyType={option} />
                </li>
              )
            }
            renderTags={(tagValue) => {
              return tagValue.map((option) => (
                <div style={{ paddingRight: "5px" }} key={option}>
                  <CandyTypeButton
                    size="medium"
                    candyType={option}
                    onDelete={() => {
                      onChange({
                        ...filter,
                        types: undefined,
                      });
                    }}
                  />
                </div>
              ));
            }}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Filter candy types")}
                placeholder="Filter types"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        <Button
          variant="contained"
          color="secondary"
          disabled={Object.values(filter).filter((v) => !!v).length === 0}
          onClick={() => {
            onChange({});
          }}
          fullWidth
        >
          {t("Clear filters")}
        </Button>
      </Grid>
    </Grid>
  );
};

const TYPE_FILTER = Object.values(CandyType);
