import {
  Button,
  Fab,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CandyPage } from "../../components/layout/CandyPage";
import { usersAPI } from "../../api/UsersAPI";
import { FormattedDate } from "../../components/date/FormattedDate";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { CreateUserSheet } from "./components/CreateUserSheet";
import { useState } from "react";
import { UserInviteSheet } from "./components/UserInviteSheet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export const ADMIN_USERS_PAGE = "/admin/users";

export const AdminUsersPage = () => {
  return (
    <CandyPage fullHeight title="Admin | Users" skeleton={<PageSkeleton />}>
      <InnerAdminUsersPage />
    </CandyPage>
  );
};

const InnerAdminUsersPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isCreateUserSheetOpen, setIsCreateUserSheetOpen] = useState(false);

  const { data: users } = useSuspenseQuery(usersAPI.fetchUsers());

  const {
    mutate: createInvite,
    data: invite,
    reset: resetInvite,
  } = useMutation({
    mutationFn: usersAPI.createInvite,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [usersAPI.QUERY_KEY],
      });
    },
  });

  return (
    <>
      <CreateUserSheet
        onClose={() => setIsCreateUserSheetOpen(false)}
        isOpen={isCreateUserSheetOpen}
      />
      <UserInviteSheet invite={invite} onClose={() => resetInvite()} />
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Last login")}</TableCell>
              <TableCell align="right">{t("Invite")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>{user.displayName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{<FormattedDate date={user.lastLogin} />}</TableCell>
                <TableCell align="right">
                  <Button
                    size="small"
                    onClick={() => createInvite(user.userId)}
                  >
                    {t("Create invite")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 20, right: 20 }}
        variant="extended"
        onClick={() => setIsCreateUserSheetOpen(true)}
        size="medium"
      >
        <PersonAddIcon sx={{ mr: 1 }} /> new
      </Fab>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" />
    </>
  );
};
