import { AtomEffect, atom } from "recoil";

const localStorageEffect =
  (key: string): AtomEffect<SettingsState> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: SettingsState, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

interface SettingsState {
  cameraDeviceId?: string;
}

const defaultState = (): SettingsState => ({
  cameraDeviceId: undefined,
});

const STATE_VERSION = "1";
const STATE_KEY = `settings-state-${STATE_VERSION}`;

export const settingsState = atom<SettingsState>({
  key: STATE_KEY,
  default:
    (localStorage.getItem(STATE_KEY) as unknown as SettingsState) ||
    defaultState(),
  effects: [localStorageEffect(STATE_KEY)],
});
