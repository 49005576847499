import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { CandyPage } from "../../components/layout/CandyPage";
import { ErrorBoundary } from "../../components/layout/ErrorBoundary";
import {
  Box,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MuiButtonLink, MuiLink } from "../../components/MuiLink";
import HistoryIcon from "@mui/icons-material/History";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { FormattedPrice } from "../../components/FormattedPrice/FormattedPrice";
import { ORDER_LIST_PAGE_ROUTE } from "./OrderListPage/OrderListPage";
import { ORDER_NEXT_PAGE_ROUTE } from "./OrderNextPage/OrderNextPage";
import { orderAPI } from "../../api/OrderAPI";
import { generatePath } from "react-router";
import { FormattedDate } from "../../components/date/FormattedDate";
import { ORDER_PAGE_ROUTE } from "./OrderPage/OrderPage";
import { ORDER_SCAN_PAGE_ROUTE } from "./OrderScanPage/OrderScanPage";
import { Link } from "react-router-dom";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";

export const ORDER_DASHBOARD_PAGE_ROUTE = "/order";

export const OrderDashboardPage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Orders">
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { data: nextOrderItems } = useQuery(orderAPI.fetchNextOrder());

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={ORDER_NEXT_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<PendingActionsRoundedIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "space-between",
              }}
            >
              {t("View next order")}
              {nextOrderItems && nextOrderItems.length > 0 && (
                <Box
                  sx={{
                    minWidth: 24,
                    fontSize: 10,
                    fontWeight: 700,
                    backgroundColor: "error.main",
                    borderRadius: 1,
                    textAlign: "center",
                  }}
                >
                  {nextOrderItems.length}
                </Box>
              )}
            </Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={ORDER_LIST_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<HistoryIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("View all orders")}</Box>
          </MuiButtonLink>
        </Grid>
      </Grid>
      <ErrorBoundary skeleton={<OverviewSkeleton />}>
        <Overview />
      </ErrorBoundary>
    </>
  );
};

const Overview = () => {
  const { t } = useTranslation();

  const { data: orders } = useSuspenseQuery(orderAPI.fetchOrders());

  // TODO: fix this to be based on page and totalElements
  const showMoreButton = true;

  return (
    <>
      <TableContainer sx={{ flex: 1, my: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t("Reference")}</TableCell>
              <TableCell>{t("Article count")}</TableCell>
              <TableCell>{t("Weight")}</TableCell>
              <TableCell>{t("Price")}</TableCell>
              <TableCell>{t("Sent")}</TableCell>
              <TableCell>{t("Delivered")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <Typography
                    variant="overline"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {t("There's nothing here yet")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {orders.map((order) => (
              <TableRow key={order.orderId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(ORDER_PAGE_ROUTE, {
                      orderId: order.orderId,
                    })}
                  >
                    {order.externalOrderReference}
                  </MuiLink>
                </TableCell>
                <TableCell>{order.itemCount}</TableCell>
                <TableCell>{order.orderWeight} kg</TableCell>
                <TableCell>
                  <FormattedPrice
                    value={order.totalPrice + order.shippingCost}
                    currency="SEK"
                  />
                </TableCell>
                <TableCell>
                  <FormattedDate date={order.ordered} />
                </TableCell>
                <TableCell>
                  {order.delivered ? (
                    <FormattedDate date={order.delivered} />
                  ) : (
                    <MuiButtonLink
                      to={generatePath(ORDER_SCAN_PAGE_ROUTE, {
                        orderId: order.orderId,
                      })}
                      color="secondary"
                      variant="outlined"
                      size="small"
                    >
                      {t("Reconciliate")}
                    </MuiButtonLink>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showMoreButton && (
        <MuiButtonLink
          to={ORDER_LIST_PAGE_ROUTE}
          variant="text"
          color="secondary"
          fullWidth
          size="large"
        >
          {t("Show all")}
        </MuiButtonLink>
      )}
    </>
  );
};

const OverviewSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
