import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryAPI } from "../../../../../api/InventoryAPI";
import { ConfirmMenuItem } from "../../../../../components/ConfirmButton";

export const InventoryReconciliationContextMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: reset } = useMutation({
    mutationFn: inventoryAPI.resetReconciliation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: inventoryAPI.getCurrentReconciliation().queryKey,
      });
    },
  });

  const refresh = () => {
    queryClient.invalidateQueries({
      queryKey: inventoryAPI.getCurrentReconciliation().queryKey,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="context-button"
        aria-controls={open ? "context-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            refresh();
            handleClose();
          }}
        >
          Refresh
        </MenuItem>
        <ConfirmMenuItem
          onConfirm={() => {
            reset();
            handleClose();
          }}
          confirmText="Clear all items in the reconciliation"
        >
          <Typography color="error">Reset reconciliation</Typography>
        </ConfirmMenuItem>
      </Menu>
    </div>
  );
};
