import { ArticleId } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export interface ProductInventory {
  articleId: ArticleId;
  displayName: string;
  orders: number; //Summering av underliggande
  inStock: boolean; //OR av underliggande
  pickAndMix? : {
    inStock: boolean; //Om den är in-stock i webshop
    orders: number
  },
  bigPack? : {
    inStock: boolean; //Om den är in-stock i webshop
    orders: number
  },
  currentStock: number; //Hur många lådor vi har i lager
  providerStock: number; //Hur mycket som finns hos leverantör
}

const QUERY_KEY = "webshop";

export const webshopAPI = {
  fetchProductInventory: (): RequiredQueryOptions<ProductInventory[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<ProductInventory[]>(`/webshop/inventory`),
  }),

  flagOutOfStock: (articleId: ArticleId) =>
    API.post(`/webshop/products/${articleId}/out-of-stock`),

  flagInStock: (articleId: ArticleId) =>
    API.post(`/webshop/products/${articleId}/set-in-stock`),

  //Jag cachear produkterna rätt hårt så vi inte behöver hamra på webshoppen, om nåt är knasigt så kan man alltid bara köra en delete
  flushProductCache: () => API.delete(`/webshop/product-cache`),
};
