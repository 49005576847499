import { UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

const QUERY_KEY = "auth";

export enum RemoteLoginStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  ABORTED = "ABORTED",
}

export type RemoteId = string & { isRemoteId: true };
export type StoreId = string & { isStoreId: true };

export interface User {
  displayName: string;
  loggedIn: UTCDate;
  ip: string;
  selectedStore: StoreId;
}

export interface Store {
  storeId: StoreId;
  displayName: string;
  active: boolean;
}

export const authAPI = {
  QUERY_KEY,
  fetchRemoteLoginSessionStatus: (
    id: string
  ): RequiredQueryOptions<{ status: RemoteLoginStatus }> => ({
    queryKey: [QUERY_KEY, id],
    queryFn: () =>
      API.get<{ status: RemoteLoginStatus }>(`/auth/login/token/${id}`),
    staleTime: 0,
  }),

  initRemoteLoginSession: () =>
    API.post<{ remoteId: RemoteId }>(`/auth/login/token`),

  whoami: (): RequiredQueryOptions<User> => ({
    queryKey: [QUERY_KEY, "whoami"],
    queryFn: () => API.get<User>(`/whoami`),
    refetchOnWindowFocus: false,
  }),

  getAvailableStores: (): RequiredQueryOptions<Store[]> => ({
    queryKey: [QUERY_KEY, "stores"],
    queryFn: () => API.get<Store[]>(`/whoami/stores`),
    refetchOnWindowFocus: false,
  }),

  selectStore: (storeId: StoreId) =>
    API.post<Store[]>(`/whoami/stores`, {
      storeId,
    }),

  logout: () => API.delete(`/auth/login`),
};
