import { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { useReconcileInventory } from "../hooks/useReconcileInventory";
import { useTranslation } from "react-i18next";
import { INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE } from "../../InventoryReconciliationDashboard";
import { useSnackBar } from "../../../../../hooks/useSnackbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { inventoryAPI } from "../../../../../api/InventoryAPI";
import { useSuspenseQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";
import { SuspenseWrapper } from "../../../../../components/layout/SuspenseWrapper";
import { MuiLink } from "../../../../../components/MuiLink";
import { Link } from "react-router-dom";
import { ARTICLE_PAGE_ROUTE } from "../../../../Article/ArticlePage/ArticlePage";

export const InventoryReconcilationDoneButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const { mutateAsync, isPending } = useReconcileInventory();

  const doReconcileOrder = useCallback(async () => {
    return mutateAsync(undefined, {
      onSuccess: () => {
        showSnackBar(t("Reconciliation marked as complete"), "success");
        navigate(INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE);
      },
      onError: () => {
        showSnackBar(t("Failed to mark reconciliation as complete"), "error");
      },
    });
  }, [mutateAsync, showSnackBar, t, navigate]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={handleOpen}
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("Mark as done")}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{t("Confirm inventory reconciliation")}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            {t(
              "Please review the changes before marking the reconciliation as done."
            )}
          </Typography>
          <SuspenseWrapper
            skeleton={
              <Grid2 container spacing={1}>
                {[...Array(20)].map((_, i) => (
                  <Grid2 key={i} size={12}>
                    <Skeleton variant="rounded" height={40} />
                  </Grid2>
                ))}
              </Grid2>
            }
          >
            <Inner showAll={showAll} />
          </SuspenseWrapper>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              mr: "auto",
            }}
            onClick={() => setShowAll(!showAll)}
            color={showAll ? "secondary" : "inherit"}
            variant={showAll ? "contained" : "text"}
            size="small"
          >
            {t("Show unchanged")}
          </Button>
          <Button onClick={handleClose} color="inherit">
            {t("Cancel")}
          </Button>
          <LoadingButton
            loading={isPending}
            onClick={doReconcileOrder}
            variant="contained"
          >
            {t("Confirm & finalize")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Inner = ({ showAll }: { showAll: boolean }) => {
  const { data } = useSuspenseQuery(
    inventoryAPI.getCurrentReconciliationReport()
  );

  const filteredArticles = useMemo(() => {
    return data.articles
      .filter((item) => showAll || item.after !== item.before)
      .sort(
        (a, b) => Math.abs(b.after - b.before) - Math.abs(a.after - a.before)
      );
  }, [data.articles, showAll]);

  return (
    <TableContainer sx={{ flex: 1, mx: -2 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                background: "transparent",
              }}
            >
              {t("Article")}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                background: "transparent",
              }}
            >
              {t("Before")}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                background: "transparent",
              }}
            >
              {t("After")}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                background: "transparent",
              }}
            >
              {t("Change")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredArticles.map((item) => (
            <TableRow key={item.articleId}>
              <TableCell>
                <MuiLink
                  component={Link}
                  to={generatePath(ARTICLE_PAGE_ROUTE, {
                    articleId: item.articleId,
                  })}
                >
                  {item.displayName}
                </MuiLink>
              </TableCell>
              <TableCell align="right">{item.before}</TableCell>
              <TableCell align="right">{item.after}</TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "700",
                  color:
                    item.after - item.before > 0
                      ? "success.main"
                      : item.after - item.before === 0
                      ? "text.primary"
                      : "error.main",
                }}
              >
                {item.after - item.before > 0
                  ? `+${item.after - item.before}`
                  : item.after - item.before}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
