import { ArticleId, CandyType, UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export enum ProviderStatus {
  IN_STOCK = "IN_STOCK",
  NO_STOCK = "NO_STOCK",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export interface PiecePrice {
  articleId: ArticleId;
  displayName: string;
  type: CandyType;
  provider: ProviderStatus;
  pieces: number;
  lastOrder?: UTCDate;
  onOrder: number;
  currentPrice: number; //SEK
  priceUpdated: boolean;
  purchasePriceUpdated: UTCDate; //När current price updaterades senast
  purchasePrice: number; //SEK Vad vi köpte in dom för
  priceSet?: UTCDate;
  priceSetBy?: string;
  selectedPrice?: number; // EUR, det är butikspriset
}

export interface PiecePriceHistory {
  priceSet: UTCDate;
  priceSetBy: string;
  purchasePrice: number; //SEK
  selectedPrice: number; // EUR, det är butikspriset
}

export interface EuroRate {
  sekToEuro: number; //11.8060
}

const QUERY_KEY = "PIECE_PRICE";

export const piecePriceAPI = {
  QUERY_KEY,
  fetchFullList: (): RequiredQueryOptions<PiecePrice[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<PiecePrice[]>(`/pieces`),
  }),
  updatePrice: (articleId: ArticleId, selectedPrice: number) =>
    API.post(`/pieces/update`, {
      articleId,
      selectedPrice,
    }),
  fetchPriceHistory: (articleId: ArticleId) =>
    API.get<PiecePriceHistory[]>(`/pieces/history/${articleId}`),
  loadExchangeRate: () => API.get<EuroRate>("/pieces/exchangerate"),
};
