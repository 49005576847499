import { UTCDate } from "./CandyAPI";
import { API, RequiredQueryOptions } from "./network/API";

export type UserId = string & { isUserId: true };
export interface CandyUser {
  userId: UserId;
  email: string;
  displayName: string;
  created: UTCDate;
  lastLogin?: UTCDate;
}

export interface UserInvite {
  id: string;
  userId: UserId;
  created: UTCDate;
  invitedBy: string;
}

export interface UpdateUser {
  email: string;
  displayName: string;
}

const QUERY_KEY = "users";

export const usersAPI = {
  QUERY_KEY,
  fetchUsers: (): RequiredQueryOptions<CandyUser[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<CandyUser[]>(`/users`),
  }),

  fetchInvites: (): RequiredQueryOptions<UserInvite[]> => ({
    queryKey: [QUERY_KEY, "invites"],
    queryFn: () => API.get<UserInvite[]>(`/users/invites`),
  }),

  createInvite: (userId: UserId) =>
    API.post<UserInvite>(`/users/${userId}/invite`),

  createUser: (newUser: UpdateUser) => API.post<CandyUser[]>(`/users`, newUser),

  updateUser: (user: CandyUser) =>
    API.post(`/users/${user.userId}`, {
      email: user.email,
      displayName: user.displayName,
    }),

  deleteUser: (userId: UserId) => API.delete(`/users/${userId}`),
};
