import { CandyPage } from "../../../components/layout/CandyPage";
import {
  AppBar,
  Box,
  Fab,
  Grid2 as Grid,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { ConfirmIconButton } from "../../../components/ConfirmButton";
import { useSnackBar } from "../../../hooks/useSnackbar";
import { Scanner } from "../../../components/Scanner";
import { Html5QrcodeResult } from "html5-qrcode";
import { ArticleId } from "../../../api/CandyAPI";
import { useFeedback } from "../../../hooks/useFeedback";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { DevButtons } from "../../../components/DevButtons";
import RemoveIcon from "@mui/icons-material/Remove";
import { ReconciliationEntry, orderAPI } from "../../../api/OrderAPI";
import { useTranslation } from "react-i18next";
import { useOrderId } from "../../../hooks/useOrderId";
import { useReconcileArticle } from "./hooks/useReconcileArticle";
import { RecentlyScanned } from "./components/RecentlyScanned";
import { AnimatePresence, motion } from "framer-motion";
import { ConfirmReconcileDialog } from "./components/ConfirmReconcileDialog";
import { MarkOrderDoneButton } from "./components/MarkOrderDoneButton";
import { useSuspenseQuery } from "@tanstack/react-query";

interface ReconcileEanProps {
  ean: string;
  articleId?: never;
}

interface ReconcileArticleIdProps {
  articleId: ArticleId;
  ean?: never;
}

type ReconcileProps = ReconcileEanProps | ReconcileArticleIdProps;

export const ORDER_SCAN_PAGE_ROUTE = "/order/:orderId/scan";

export const OrderScanPage: React.FunctionComponent = () => {
  return (
    <CandyPage
      fullHeight
      title="Order Reconciliation"
      skeleton={<PageSkeleton />}
    >
      <Inner />
    </CandyPage>
  );
};

enum ReconciliationView {
  REMAINING = "REMAINING",
  SCANNED = "SCANNED",
}

const Inner: React.FunctionComponent = () => {
  const orderId = useOrderId();
  const [view, setView] = useState<ReconciliationView>(
    ReconciliationView.REMAINING
  );
  const { showSnackBar } = useSnackBar();
  const feedback = useFeedback();
  const { t } = useTranslation();
  const theme = useTheme();
  const [latestCompletedItem, setLatestCompletedItem] =
    useState<ReconciliationEntry>();
  const [confirmItem, setConfirmItem] = useState<ReconciliationEntry>();

  const { data: items } = useSuspenseQuery(
    orderAPI.fetchOrderReconciliation(orderId)
  );

  const { mutate: reconcileArticle, data: reconciledData } =
    useReconcileArticle();

  const latestItem = useMemo(() => {
    return reconciledData?.item;
  }, [reconciledData]);

  useLayoutEffect(() => {
    if (latestItem && latestItem.delivered >= latestItem.confirmed) {
      setLatestCompletedItem(latestItem);
      setTimeout(() => {
        setLatestCompletedItem(undefined);
      }, 1000);
    }
  }, [latestItem]);

  const completedItems = useMemo(() => {
    return items?.filter((item) => item.delivered >= item.confirmed);
  }, [items]);

  const remainingItems = useMemo(() => {
    return items?.filter((item) => item.delivered < item.confirmed);
  }, [items]);

  const doReconcileArticle = useCallback(
    ({ ean, articleId }: ReconcileProps) => {
      reconcileArticle(
        typeof articleId === "undefined"
          ? { orderId, ean }
          : { orderId, articleId },
        {
          onSuccess: () => {
            feedback();
          },
          onError: () => {
            feedback();
            // TODO: nicer presentation, not ean
            showSnackBar(
              t("{{article}} not recognized or not found on order ", {
                article: ean,
              }),
              "warning"
            );
          },
        }
      );
    },
    [feedback, orderId, reconcileArticle, showSnackBar, t]
  );

  const onScan = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      console.info(`Code matched = ${decodedText}`, decodedResult);
      doReconcileArticle({ ean: decodedText });
    },
    [doReconcileArticle]
  );

  const changeView = () => {
    setView(
      view === ReconciliationView.REMAINING
        ? ReconciliationView.SCANNED
        : ReconciliationView.REMAINING
    );
  };

  const removeHandledItem = (articleId: ArticleId) => {
    // TODO: implement remove handler articleId
    console.log("removeHandledItem", articleId);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <ConfirmReconcileDialog
        confirmText={t("Mark one {{article}} as delivered?", {
          article: confirmItem?.displayName || "",
        })}
        onClose={() => setConfirmItem(undefined)}
        onConfirm={() => {
          if (!confirmItem) {
            return;
          }
          doReconcileArticle({ articleId: confirmItem.articleId });
        }}
        open={!!confirmItem}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 48px)",
        }}
      >
        {view === ReconciliationView.REMAINING && (
          <>
            <Box
              sx={{
                flex: 1,
                position: "relative",
                display: "flex",
                maxHeight: "60%",
                "&:after": (theme) => ({
                  content: '""',
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  left: 0,
                  height: 80,
                  background: `linear-gradient(to top, ${theme.palette.background.paper} 0%, transparent 100%)`,
                }),
              }}
            >
              <TableContainer
                sx={{
                  maxWidth: "100%",
                  position: "relative",
                  paddingBottom: 8,
                  flex: 1,
                }}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        width={"1"}
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("Article ID")}
                      </TableCell>
                      <TableCell>{t("Article")}</TableCell>
                      <TableCell align="right">{t("Quantity")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remainingItems.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography variant="body1">
                            {t("All articles marked as delivered")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {remainingItems.map((candy) => (
                      <TableRow
                        key={candy.articleId}
                        hover
                        component={motion.tr}
                        layout
                        layoutId={`row-${candy.articleId}`}
                        animate={{
                          background:
                            latestItem?.articleId === candy.articleId
                              ? [
                                  "#00000000",
                                  theme.palette.success.light + "66",
                                  "#00000000",
                                ]
                              : "#00000000",
                          transition: {
                            duration: 0.75,
                          },
                        }}
                      >
                        <TableCell
                          width={1}
                          size="medium"
                          onClick={() => setConfirmItem(candy)}
                        >
                          {candy.articleId}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "0.1%",
                          }}
                          onClick={() => setConfirmItem(candy)}
                        >
                          <Box
                          // sx={{
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   whiteSpace: "nowrap",
                          //   // width: "auto",
                          //   maxWidth: "30%",
                          // }}
                          >
                            {candy.displayName}
                          </Box>
                        </TableCell>
                        <TableCell
                          onClick={() => setConfirmItem(candy)}
                          align="right"
                        >
                          {candy.delivered} / {candy.confirmed}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  bottom: 0,
                  pb: 2,
                }}
              >
                <MarkOrderDoneButton orderId={orderId} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                overflow: "hidden",
                position: "relative",
                background: (theme) => theme.palette.action.focus,
              }}
            >
              <RecentlyScanned entry={latestItem} />
              <DevButtons onScanSuccess={onScan} position="bottom" />
              <Scanner
                onScanSuccess={onScan}
                onScanError={() => {
                  // TODO: Show error
                }}
                style={{
                  flex: 1,
                  objectFit: "cover",
                }}
              />
            </Box>
          </>
        )}

        {view === ReconciliationView.SCANNED && (
          <>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                height: "100%",

                "&:after": (theme) => ({
                  content: '""',
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  left: 0,
                  height: 80,
                  background: `linear-gradient(to top, ${theme.palette.background.paper} 0%, transparent 100%)`,
                }),
              }}
            >
              <TableContainer
                sx={{
                  height: "100%",
                  position: "relative",
                  paddingBottom: 8,
                  flex: 1,
                }}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width={1} sx={{ whiteSpace: "nowrap" }}>
                        {t("Article ID")}
                      </TableCell>
                      <TableCell>{t("Article")}</TableCell>
                      <TableCell align="right">{t("Quantity")}</TableCell>
                      <TableCell align="right">{t("Action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedItems.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          <Typography variant="body1">
                            {t("There are no articles marked as delivered")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {completedItems.map((candy) => (
                      <TableRow key={candy.articleId} hover>
                        <TableCell width={1}>{candy.articleId}</TableCell>
                        <TableCell>{candy.displayName}</TableCell>
                        <TableCell align="right">
                          {candy.delivered} / {candy.confirmed}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <ConfirmIconButton
                            color="error"
                            confirmText={t(
                              "Remove one {{article}} from scanned articles?",
                              { article: candy.articleId }
                            )}
                            onConfirm={() => {
                              // TODO: Handle remove from scanned
                              removeHandledItem(candy.articleId);
                              showSnackBar(
                                t("Removed {{article}} from scanned articles", {
                                  article: candy.articleId,
                                }),
                                "info"
                              );
                            }}
                          >
                            <RemoveIcon />
                          </ConfirmIconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {completedItems.length > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <Fab
                    variant="extended"
                    size="medium"
                    color="success"
                    onClick={() => {
                      // TODO: Add list stock & mark order as picked
                      console.log("Add list stock & mark order done");
                    }}
                  >
                    {t("Mark order as processed")}
                  </Fab>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      <AppBar
        position="relative"
        color="transparent"
        elevation={0}
        sx={{
          flex: "1 1 0px",
        }}
      >
        <Tabs
          value={view}
          onChange={changeView}
          centered
          variant="fullWidth"
          TabIndicatorProps={{
            sx: {
              top: 0,
            },
          }}
        >
          <Tab value={ReconciliationView.REMAINING} label={t("Remaining")} />
          <Tab
            value={ReconciliationView.SCANNED}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <span>{t("Completed")}</span>
                <AnimatePresence>
                  {latestCompletedItem && (
                    <Box
                      component={motion.div}
                      layout
                      layoutId={`row-${latestCompletedItem?.articleId}`}
                      initial={{
                        width: 0,
                        opacity: 0,
                      }}
                      animate={{
                        width: "auto",
                        opacity: 1,
                      }}
                      exit={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                      }}
                      sx={{
                        color: "success",
                        zIndex: 99999,
                        overflow: "hidden",
                        transformOrigin: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: (theme) => theme.palette.secondary.main,
                          color: (theme) =>
                            theme.palette.secondary.contrastText,
                          py: 0.2,
                          px: 0.5,
                          borderRadius: 10,
                          ml: 1,
                          fontSize: "0.75rem",
                        }}
                      >
                        +{latestCompletedItem.delivered}
                      </Box>
                    </Box>
                  )}
                </AnimatePresence>
              </Box>
            }
          />
        </Tabs>
      </AppBar>
    </Box>
  );
};

const PageSkeleton = () => {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid size={12}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      {[...Array(20)].map((_, i) => (
        <Grid key={i} size={12}>
          <Skeleton variant="rounded" height={50} />
        </Grid>
      ))}
    </Grid>
  );
};
