import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSaveArticle } from "../../../../hooks/useSaveArticle";
import { Controller, useForm } from "react-hook-form";
import {
  CandyArticle,
  CandyType,
  Nutrients,
  candyAPI,
} from "../../../../api/CandyAPI";
import { useSnackBar } from "../../../../hooks/useSnackbar";
import { queryClient } from "../../../../App";
import { CandyTypeString } from "../../../../components/CandyTypeChip";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  article: CandyArticle;
}

export const UpdateArticleDialog: FC<Props> = ({
  isOpen,
  onClose,
  article,
}) => {
  const { t } = useTranslation();

  const { showSnackBar } = useSnackBar();

  const { handleSubmit, register, reset, control } = useForm<CandyArticle>({
    defaultValues: {
      ...article,
    },
  });

  const { mutate: saveArticle, isPending: isSaving } = useSaveArticle(
    article.articleId
  );

  useEffect(() => {
    reset(article);
  }, [article, reset]);

  const onFinish = (data: CandyArticle) => {
    (Object.keys(data.nutritional) as (keyof Nutrients)[]).forEach((key) => {
      if (data.nutritional[key] === "") {
        delete data.nutritional[key];
      }
    });

    saveArticle(
      { ...article, ...data, nutritional: data.nutritional },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: candyAPI.fetchArticle(article.articleId).queryKey,
          });
          onClose();
          showSnackBar(t("Saved article"), "success");
        },
        onError: () => {
          showSnackBar(t("Failed to save article"), "error");
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        {t("Update article {{ displayName }}", {
          displayName: article.displayName,
        })}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onFinish)}>
          <Grid2 container spacing={3} sx={{ pt: 2 }}>
            <Grid2
              container
              spacing={3}
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Grid2
                size={{
                  xs: 12,
                }}
              >
                <TextField
                  fullWidth
                  label={t("Display Name")}
                  id="displayName"
                  inputRef={register("displayName").ref}
                  {...register("displayName")}
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                }}
              >
                <TextField
                  fullWidth
                  label="EAN"
                  id="ean"
                  {...register("ean")}
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="candyType">{t("Candy Type")}</InputLabel>
                  <Controller
                    name="candyType"
                    control={control}
                    defaultValue={"" as unknown as undefined}
                    render={({ field }) => (
                      <Select
                        labelId="candyType"
                        label={t("Candy Type")}
                        fullWidth
                        {...(field as SelectProps)}
                      >
                        {Object.values(CandyType).map((candyType) => (
                          <MenuItem value={candyType} key={candyType}>
                            {CandyTypeString(t)[candyType]}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid2>
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("Properties")}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox color="primary" size="large" />}
                    label={t("Gelatin free")}
                    labelPlacement="end"
                    {...register("gelatinFree")}
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" size="large" />}
                    label={t("Palm oil free")}
                    labelPlacement="end"
                    {...register("palmOilFree")}
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" size="large" />}
                    label={t("Vegan")}
                    labelPlacement="end"
                    {...register("vegan")}
                  />
                </FormGroup>
              </FormControl>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label={t("Ingredients")}
                multiline
                minRows={5}
                {...register("ingredients")}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label={t("Original Ingredient label")}
                multiline
                disabled
                minRows={5}
                {...register("ingredientsOriginal")}
              />
            </Grid2>

            <Grid2 size={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Nutrient")}</TableCell>
                      <TableCell>{t("Value")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {NUTRIENT_KEYS.map((key) => (
                      <TableRow hover key={key}>
                        <TableCell width={1}>{key}</TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            size="small"
                            {...register(`nutritional.${key}`)}
                            placeholder="-"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid2>
          </Grid2>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              display: "flex",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              fullWidth
              onClick={onClose}
              variant="contained"
              color="inherit"
            >
              {t("Cancel")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={isSaving}
            >
              {t("Save")}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const NUTRIENT_KEYS: (keyof Nutrients)[] = [
  "kj",
  "kcal",
  "fat",
  "satFat",
  "carbs",
  "sugar",
  "protein",
  "salt",
  "correspondingSalt",
  "natrium",
  "fiber",
  "starch",
  "polyoler",
];
