import {
  Button,
  ButtonProps,
  IconButton,
  Link as MaterialLink,
} from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

export const MuiLink = MaterialLink;

export const MuiButtonLink = ({ ...props }: LinkProps & ButtonProps) => {
  return <Button LinkComponent={Link} {...(props as ButtonProps)} />;
};

export const MuiIconButtonLink = ({ ...props }: LinkProps & ButtonProps) => {
  return <IconButton component={Link} {...(props as ButtonProps)} />;
};
