import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { useAddToOrder } from "../hooks/useAddToOrder";
import { useSnackBar } from "../hooks/useSnackbar";

interface Props {
  articleId: ArticleId;
  displayName?: string;
  existingOrder?: number;
  onClose?: () => void;
  disabled?: boolean;
}

function selectColor(existingOrder: number, disabled: boolean) {
  if (disabled) {
    return "secondary";
  }

  if (existingOrder === 0) {
    return "primary";
  }
  return "success";
}

export const AddToOrderDialog: FunctionComponent<Props> = ({
  articleId,
  displayName,
  existingOrder = 0,
  disabled = false,
  onClose,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [internalCount, setInternalCount] = useState<number>(existingOrder);
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const iconColor = selectColor(existingOrder, disabled);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    setInternalCount(existingOrder);
  }, [existingOrder]);

  const { mutate: addToOrder } = useAddToOrder();
  const handleOk = () => {
    addToOrder(
      { articleId, count: internalCount },
      {
        onSuccess: () => {
          showSnackBar(
            t("Added {{quantity}} {{article}} to next order ", {
              article: displayName,
              quantity: internalCount,
            }),
            "success"
          );
          handleClose();
        },
        onError: () => {
          showSnackBar(
            t("Failed to add {{quantity}} {{article}} to next order", {
              article: displayName,
              quantity: internalCount,
            }),
            "error"
          );
        },
      }
    );
  };

  return (
    <>
      <IconButton disabled={disabled} onClick={handleOpen} color={iconColor}>
        <AddShoppingCartIcon fontSize="inherit" sx={{ mr: 0.5 }} />
        {/* {t("Order", { article: displayName || articleId })} */}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {t("Order {{article}}", { article: displayName || articleId })}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Button
            onClick={() => setInternalCount(Math.max(0, internalCount - 1))}
            size="large"
            color="secondary"
          >
            <RemoveIcon fontSize="large" />
          </Button>
          <Box>
            <Typography variant="h5">{internalCount}</Typography>
          </Box>
          <Button
            onClick={() => setInternalCount(internalCount + 1)}
            size="large"
            color="secondary"
          >
            <AddIcon fontSize="large" />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOk}
            variant="contained"
            fullWidth
            color="primary"
          >
            {t("Update")}
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            fullWidth
            color="inherit"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
