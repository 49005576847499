import CreateIcon from "@mui/icons-material/Create";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PiecePrice,
  PiecePriceHistory,
  piecePriceAPI,
} from "../../api/PiecePriceAPI";
import { FormattedDate } from "../../components/date/FormattedDate";
import { useBreakpointDown } from "../../hooks/useBreakpoint";
import { useSnackBar } from "../../hooks/useSnackbar";

interface SetPriceProps {
  piece: PiecePrice;
  sekToEuro: number;
  suggestedPrice: number;
  suggestedMargin: number;
}

function calculateMargin(selectedPrice: number, purchasePrice: number) {
  return ((selectedPrice - purchasePrice * 1.21) / selectedPrice) * 100;
}

export const SetPriceDialog: FunctionComponent<SetPriceProps> = ({
  piece,
  sekToEuro,
  suggestedPrice,
  suggestedMargin,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorPrice, setErrorPrice] = useState<boolean>(false);
  const [noPicture, setNoPicture] = useState<boolean>(false);
  const [priceHistory, setPriceHistory] = useState<PiecePriceHistory[]>([]);
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const sm = useBreakpointDown("sm");

  const [selectedPrice, setSelectedPrice] = useState(
    piece.selectedPrice ? piece.selectedPrice.toString() : ""
  );

  useEffect(() => {
    if (open) {
      setPriceHistory([]);
      piecePriceAPI.fetchPriceHistory(piece.articleId).then(setPriceHistory);
    }
  }, [open, piece.articleId]);

  const euroPrice = piece.purchasePrice / piece.pieces / sekToEuro;

  const { mutate: handleSave } = useMutation({
    mutationFn: (price: number) =>
      piecePriceAPI.updatePrice(piece.articleId, price),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [piecePriceAPI.QUERY_KEY] });
      setSaving(false);
      showSnackBar(t("Saved"), "success");
      setOpen(false);
    },
    onError: () => {
      showSnackBar(t("Failed to save price"), "error");
    },
  });

  const save = () => {
    setSaving(true);
    const price = parseFloat(selectedPrice.replace(",", "."));
    handleSave(price);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
          }}
        >
          {piece.selectedPrice ? "€" + piece.selectedPrice : ""}
        </Typography>
        <Button
          color="info"
          size="large"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          <CreateIcon sx={{ fontSize: 18 }} />
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={sm ? true : false}
      >
        <DialogTitle>
          {t("Set price for")} {piece.displayName}
        </DialogTitle>

        <DialogContent
          sx={{
            display: "block",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              height={350}
              loading="lazy"
              hidden={noPicture}
              onError={() => setNoPicture(true)}
              onLoad={() => setNoPicture(false)}
              src={`https://storage.googleapis.com/candy-pictures/articles/${piece.articleId}.jpg`}
            />
            {noPicture ? <i>{t("No picture available")}</i> : null}
          </Box>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
            <OutlinedInput
              disabled={saving}
              error={errorPrice}
              type="text"
              id="outlined-adornment-amount"
              value={selectedPrice}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const price = event.target.value.replaceAll(/[^\d,.]+/g, "");
                const correctFormat = price.replace(",", ".");
                if (Number.isNaN(parseFloat(correctFormat))) {
                  setErrorPrice(true);
                } else {
                  setErrorPrice(false);
                }
                setSelectedPrice(price);
              }}
              onKeyUp={(ev) => {
                if (ev.key === "Enter" && errorPrice === false) {
                  save();
                }
              }}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Amount"
            />
          </FormControl>
          <Stack
            component="dl" // mount a Definition List
            spacing={1}
          >
            <Box>
              <Typography component="dt" variant="h6">
                {t("Margin")}:
              </Typography>
              <Typography component="dd" variant="body1">
                {selectedPrice
                  ? calculateMargin(
                      parseFloat(selectedPrice.replace(",", ".")),
                      euroPrice
                    ).toFixed(0) + " %"
                  : "-"}
              </Typography>
            </Box>
            <Box>
              <Typography component="dt" variant="h6">
                {t("Recommended (VAT Included)")}:
              </Typography>
              <Typography component="dd" variant="body1">
                €{suggestedPrice.toFixed(2)} ({suggestedMargin.toFixed(0)} %)
              </Typography>
            </Box>
            <Box>
              <Typography component="dt" variant="h6">
                {t("Item Price")}:
              </Typography>
              <Typography component="dd" variant="body1">
                €{euroPrice.toFixed(2)}
              </Typography>
            </Box>

            <Box>
              <h3>{t("Price history")}</h3>
              <Table>
                <TableBody>
                  {priceHistory
                    .filter((_, i) => i < 5)
                    .map((item) => {
                      return (
                        <TableRow key={item.priceSet}>
                          <TableCell padding="none">
                            <FormattedDate
                              date={item.priceSet}
                              pattern="yyyy-MM-dd"
                            />
                          </TableCell>
                          <TableCell padding="none">
                            {item.priceSetBy}
                          </TableCell>
                          <TableCell padding="none">
                            €{item.selectedPrice}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={save}
            disabled={errorPrice || saving}
            fullWidth
            variant="contained"
            color="primary"
          >
            {t("Save")}
          </Button>
          <Button
            disabled={saving}
            variant="contained"
            onClick={() => setOpen(false)}
            fullWidth
            color="inherit"
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
