import { Chip, colors } from "@mui/material";
import { CandyType } from "../api/CandyAPI";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export const CandyTypeString = (t: TFunction): Record<CandyType, string> => ({
  [CandyType.SWEET]: t("Sweet"),
  [CandyType.SOUR]: t("Sour"),
  [CandyType.SALTY]: t("Salty"),
  [CandyType.FOAM]: t("Foam"),
  [CandyType.LICORICE]: t("Licorice"),
  [CandyType.CHOCOLATE]: t("Chocolate"),
  [CandyType.CHIPS]: t("Chips"),
  [CandyType.CANDY_PIECE]: t("Piece"),
  [CandyType.DRINK]: t("Drink"),
  [CandyType.CHEWING_GUM]: t("Chewing gum"),
  [CandyType.PACKAGING]: t("Packaging")
});

const SHADE = 200;

const CandyTypeColor: Record<
  CandyType,
  {
    background: string;
    color: string;
  }
> = {
  [CandyType.SWEET]: {
    background: colors.blue[SHADE],
    color: "white",
  },
  [CandyType.SOUR]: {
    background: colors.green[SHADE],
    color: "white",
  },
  [CandyType.SALTY]: {
    background: colors.blueGrey[SHADE],
    color: "white",
  },
  [CandyType.FOAM]: {
    background: colors.pink[SHADE],
    color: "white",
  },
  [CandyType.LICORICE]: {
    background: colors.grey[600],
    color: "white",
  },
  [CandyType.CHOCOLATE]: {
    background: colors.brown[SHADE],
    color: "white",
  },
  [CandyType.CHIPS]: {
    background: colors.orange[300],
    color: "white",
  },
  [CandyType.CANDY_PIECE]: {
    background: colors.purple[SHADE],
    color: "white",
  },
  [CandyType.DRINK]: {
    background: colors.teal[SHADE],
    color: "white",
  },
  [CandyType.CHEWING_GUM]: {
    background: colors.indigo[SHADE],
    color: "white",
  },
  [CandyType.PACKAGING]: {
    background: colors.brown[SHADE],
    color: "white",
  }
};

export const CandyTypeChip = ({
  candyType,
  size = "small",
}: {
  candyType?: CandyType;
  size?: "small" | "medium";
}) => {
  const { t } = useTranslation();
  if (!candyType) {
    return null;
  }

  const { background, color } = CandyTypeColor[candyType];

  return (
    <Chip
      label={CandyTypeString(t)[candyType]}
      size={size}
      sx={{
        backgroundColor: background,
        color,
        fontWeight: "bold",
      }}
    />
  );
};

export const CandyTypeButton = ({
  candyType,
  size = "small",
  onDelete
}: {
  candyType?: CandyType;
  size?: "small" | "medium";
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();
  if (!candyType) {
    return null;
  }

  const { background, color } = CandyTypeColor[candyType];

  return (
    <Chip
      label={CandyTypeString(t)[candyType]}
      size={size}
      sx={{
        backgroundColor: background,
        color,
        fontWeight: "bold",
      }}
      onDelete={onDelete}
    />
  );
};
