import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { useSnackBar } from "../hooks/useSnackbar";
import { useUpdateInventory } from "../hooks/useUpdateInventory";
import { useQueryClient } from "@tanstack/react-query";
import { inventoryAPI } from "../api/InventoryAPI";
import { scanAPI } from "../api/ScanAPI";
import { LoadingButton } from "@mui/lab";

interface Props {
  count: number;
  articleId: ArticleId;
  onClose: (count?: number) => void;
  isOpen: boolean;
  /**
   * @description If true, the dialog will add or subtract the count relative to the current count
   * @default false
   * */
  relative?: boolean;
}

export const EditInventoryDialog: FunctionComponent<Props> = ({
  count,
  articleId,
  onClose,
  isOpen,
  relative = false,
}) => {
  const [internalCount, setInternalCount] = useState<number>(
    relative ? Number(count) : 0
  );
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const sm = useBreakpointDown("sm");
  const { mutate: updateInventory, isPending } = useUpdateInventory(articleId);
  const queryClient = useQueryClient();

  const handleOk = () => {
    const newCount = relative
      ? Math.max(0, count + internalCount)
      : internalCount;
    updateInventory(newCount, {
      onSuccess: () => {
        setInternalCount(relative ? 0 : internalCount);
        queryClient.invalidateQueries({
          queryKey: inventoryAPI.fetchInventory(articleId).queryKey,
        });
        queryClient.invalidateQueries({
          queryKey: [scanAPI.QUERY_KEY],
        });
        showSnackBar(t("Updated inventory quantity of article"), "success");
      },
      onError: () => {
        showSnackBar(t("Error updating inventory of article"), "error");
      },
      onSettled: () => {
        onClose(internalCount);
      },
    });
  };

  const subtract = () => {
    if (relative) {
      setInternalCount(internalCount - 1);
    } else {
      setInternalCount(Math.max(internalCount - 1, 0));
    }
  };

  const add = () => {
    setInternalCount(internalCount + 1);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      fullWidth={sm ? true : false}
    >
      <DialogTitle>
        {relative
          ? t("Add/remove from inventory")
          : t("Set inventory quantity")}
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <IconButton
          disabled={!relative && internalCount === 0}
          onClick={subtract}
          size="large"
          color="secondary"
        >
          <RemoveIcon fontSize="large" />
        </IconButton>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ width: "150px" }}
            value={internalCount}
            size="medium"
            type="number"
            onChange={(e) => {
              const count = parseInt(e.target.value);
              if (Number.isNaN(count)) {
                return;
              }
              setInternalCount(count);
            }}
            variant="outlined"
            slotProps={{
              htmlInput: {
                sx: { textAlign: "center" },
              },
            }}
          />
        </Box>
        <IconButton onClick={add} size="large" color="secondary">
          <AddIcon fontSize="large" />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} fullWidth color="inherit">
          {t("Cancel")}
        </Button>
        <LoadingButton
          onClick={handleOk}
          variant="contained"
          fullWidth
          color="primary"
          loading={isPending}
        >
          {t("Update")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
