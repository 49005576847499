import { useMutation, useQueryClient } from "@tanstack/react-query";
import { orderAPI } from "../../../../api/OrderAPI";

type QueryDataType = Awaited<
  ReturnType<ReturnType<typeof orderAPI.fetchOrderReconciliation>["queryFn"]>
>;

export const useReconcileArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderAPI.reconcileArticle,
    onSuccess: (data, { orderId }) => {
      const queryKey = orderAPI.fetchOrderReconciliation(orderId).queryKey;
      queryClient.setQueryData<QueryDataType>(queryKey, data.orderItems);
    },
    onError: (_, { orderId }) => {
      const queryKey = orderAPI.fetchOrderReconciliation(orderId).queryKey;
      queryClient.invalidateQueries({ queryKey });
    },
    // onSettled: (_, __, {orderId}) => {
    //   const queryKey = orderAPI.fetchOrderReconconciliation(orderId).queryKey;
    //   queryClient.invalidateQueries(queryKey);
    // },
  });
};
