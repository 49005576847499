import { queryClient } from "./App";
import { candyAPI } from "./api/CandyAPI";
import { SortDirection } from "./api/Page";

const eans = ["4001686576106", "7340149208237"];

export const randomEAN = async () => {
  const data = await queryClient.fetchQuery(
    candyAPI.fetchArticles(0, 50, SortDirection.DESC, "created")
  );

  if (data.content) {
    return data.content[Math.floor(Math.random() * data.content.length)].ean;
  }

  return eans[Math.floor(Math.random() * eans.length)];
};

export const roundToTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const filterToParams = (
  // TODO:: should type this with generics but not that important
  filter: object,
  // TODO:: should type this with generics but not that important
  sortField?: string,
  sortDirection?: SortDirection
): Record<string, string> => ({
  ...(sortField && { sortField }),
  ...(sortDirection && { sortDirection }),
  ...Object.fromEntries(
    Object.entries(filter)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [key, value.join(",")];
        }
        return [key, value.toString()];
      })
  ),
});
