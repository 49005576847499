import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSelect = () => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleChange = useCallback(
    (_: React.MouseEvent, value: string) => {
      if (!value) {
        return;
      }
      setLanguage(value);
      i18n.changeLanguage(value);
    },
    [i18n]
  );

  return (
    <ToggleButtonGroup
      value={language}
      exclusive
      onChange={handleChange}
      fullWidth
      color="secondary"
      size="small"
    >
      <ToggleButton value="en">{t("English")}</ToggleButton>
      <ToggleButton value="lv">{t("Latvian")}</ToggleButton>
    </ToggleButtonGroup>
  );
};
