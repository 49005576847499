import { generatePath, useNavigate } from "react-router-dom";
import { translationApi } from "../../api/TranslationApi";
import { useEffect } from "react";
import { CandyPage } from "../../components/layout/CandyPage";
import {
  TRANSLATION_ARTICLE_PAGE_ROUTE,
  TranslateArticlePageSkeleton,
} from "./TranslationArticlePage/TranslationArticlePage";
import { useSuspenseQuery } from "@tanstack/react-query";

export const TRANSLATION_PAGE_ROUTE = "/translation";

export const TranslationPage: React.FunctionComponent = () => {
  return (
    <CandyPage title="Translations" skeleton={<TranslateArticlePageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { data: nextTranslation } = useSuspenseQuery(
    translationApi.fetchNextTranslation()
  );

  useEffect(() => {
    if (nextTranslation?.articleId) {
      navigate(
        generatePath(TRANSLATION_ARTICLE_PAGE_ROUTE, {
          articleId: nextTranslation.articleId,
        }),
        {
          replace: true,
        }
      );
    }
  }, [nextTranslation, navigate]);

  return null;
};
