import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

export const useBreakpointDown = (breakpoint: Breakpoint = "sm") => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.down(breakpoint));
};

export const useBreakpointUp = (breakpoint: Breakpoint = "sm") => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.up(breakpoint));
};
