import { Box, Tooltip } from "@mui/material";
import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  disabled: boolean;
  untranslated: boolean;
  onOrder: boolean;
}

// display a dot for each prop
export const ArticleStatus: FunctionComponent<PropsWithChildren<Props>> = ({
  disabled,
  untranslated,
  onOrder,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      {children}
      <Box
        sx={{
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: 0.5,
            marginBottom: -0.5,
            gap: 0.5,
          }}
        >
          {disabled && (
            <Tooltip title={t("Unavailable")}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  bgcolor: "error.main",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />
            </Tooltip>
          )}
          {untranslated && (
            <Tooltip title={t("Missing translation")}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  bgcolor: "warning.main",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />
            </Tooltip>
          )}
          {onOrder && (
            <Tooltip title={t("On order")}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  bgcolor: "info.main",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};
