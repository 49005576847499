import { Box, Grid2 as Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { CandyPage } from "../../../components/layout/CandyPage";
import { INVENTORY_RECONCILIATION_PAGE_ROUTE } from "./InventoryReconciliationPage/InventoryReconciliationPage";
import { MuiButtonLink } from "../../../components/MuiLink";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HistoryIcon from "@mui/icons-material/History";
import { INVENTORY_RECONCILIATION_HISTORY_LIST_PAGE_ROUTE } from "./InventoryReconciliationHistoryListPage/InventoryReconciliationHistoryListPage";

export const INVENTORY_RECONCILIATION_DASHBOARD_PAGE_ROUTE =
  "/inventory/reconciliation";

export const InventoryReconciliationDashboardPage: React.FunctionComponent =
  () => {
    return (
      <CandyPage title="Inventory reconciliation">
        <Inner />
      </CandyPage>
    );
  };

const Inner: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_RECONCILIATION_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<QrCodeScannerIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Current reconciliation")}</Box>
          </MuiButtonLink>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <MuiButtonLink
            to={INVENTORY_RECONCILIATION_HISTORY_LIST_PAGE_ROUTE}
            variant="contained"
            color="inherit"
            startIcon={<HistoryIcon />}
            endIcon={<ArrowRightAltIcon />}
            fullWidth
            size="large"
            sx={{ justifyContent: "flex-start" }}
          >
            <Box sx={{ flex: 1 }}>{t("Reconciliation history")}</Box>
          </MuiButtonLink>
        </Grid>
      </Grid>
    </>
  );
};
