import {
  Box,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { orderAPI } from "../../../api/OrderAPI";
import { CandyPage } from "../../../components/layout/CandyPage";
import { useOrderId } from "../../../hooks/useOrderId";
import { OrderStatusStepper } from "./components/OrderStatusStepper";
import { OrderItemRow } from "./components/OrderItemRow";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { FeatureCard } from "../../../components/Cards/FeatureCard";
import { AppTopBarActions } from "../../../components/layout/AppTopBarActions";
import { MuiButtonLink } from "../../../components/MuiLink";
import { ORDER_SCAN_PAGE_ROUTE } from "../OrderScanPage/OrderScanPage";
import { generatePath } from "react-router";
import { Helmet } from "../../../components/layout/Helmet";

export const ORDER_PAGE_ROUTE = "/order/:orderId";

export const OrderPage = () => {
  return (
    <CandyPage title="Order" skeleton={<PageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

const Inner = () => {
  const orderId = useOrderId();
  const { t } = useTranslation();

  const [{ data: orderItems }, { data: orderSummary }] = useSuspenseQueries({
    queries: [
      orderAPI.fetchOrderedItems(orderId),
      orderAPI.fetchOrder(orderId),
    ],
  });

  if (typeof orderSummary === "undefined") {
    return (
      <CandyPage fullHeight title="Order not found">
        <Typography variant="h2">{t("Order not found")}</Typography>
      </CandyPage>
    );
  }

  return (
    <>
      <Helmet title={orderSummary.externalOrderReference} />
      {!orderSummary.confirmed && (
        <AppTopBarActions>
          <MuiButtonLink
            to={generatePath(ORDER_SCAN_PAGE_ROUTE, {
              orderId: orderSummary.orderId,
            })}
            color="primary"
            size="small"
            variant="contained"
          >
            {t("Reconciliate")}
          </MuiButtonLink>
        </AppTopBarActions>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          mb: 2,
        }}
      >
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <FeatureCard
            label={t("Total cost")}
            value={
              <FormattedPrice value={orderSummary.totalPrice} currency="SEK" />
            }
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <FeatureCard label={t("Articles")} value={orderSummary.itemCount} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <FeatureCard
            label={t("Weight")}
            value={orderSummary.orderWeight + " kg"}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 6, mb: 2 }}>
        <OrderStatusStepper order={orderSummary} />
      </Box>
      <TableContainer sx={{ flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("Article")}</TableCell>
              <TableCell>{t("Display Name")}</TableCell>
              <TableCell>{t("Price")}</TableCell>
              <TableCell>{t("Ordered")}</TableCell>
              <TableCell>{t("Confirmed")}</TableCell>
              <TableCell>{t("Delivered")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map((item) => (
              <OrderItemRow
                item={item}
                order={orderSummary}
                key={item.articleId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Grid container spacing={2}>
        {[...Array(3)].map((_, i) => (
          <Grid
            size={{
              xs: 12,
              sm: 4,
            }}
            key={i}
          >
            <Skeleton variant="rounded" height={90} />
          </Grid>
        ))}
      </Grid>
      <Skeleton variant="rounded" height={100} />
      <Grid container spacing={1}>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={60} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
