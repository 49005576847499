import { PropsWithChildren, useEffect, useState } from "react";
import { APP_TOP_BAR_ACTIONS_ID } from "./AppTopBar";
import { createPortal } from "react-dom";

export const AppTopBarActions: React.FC<PropsWithChildren> = ({ children }) => {
  const [portal, setPortal] = useState<Element | null>(() =>
    document.getElementById(APP_TOP_BAR_ACTIONS_ID)
  );

  useEffect(() => {
    setPortal(document.getElementById(APP_TOP_BAR_ACTIONS_ID));
  }, []);

  if (!portal) {
    return null;
  }

  return createPortal(<>{children}</>, portal);
};
