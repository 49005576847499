import { useParams } from "react-router";
import { ArticleId } from "../api/CandyAPI";

type Param = {
  articleId: ArticleId;
};

export function useArticleId() {
  const { articleId } = useParams<Param>();
  return articleId as ArticleId;
}
