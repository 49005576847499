import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSuspendedArticleInventoryHistory } from "../hooks/useArticleInventoryHistory";
import { FormattedDate } from "./date/FormattedDate";
import { ArticleId } from "../api/CandyAPI";
import { SuspenseWrapper } from "./layout/SuspenseWrapper";
import { useState } from "react";

const DEFAULT_PAGE_SIZE = 10;

interface Props {
  articleId: ArticleId;
}

export const ArticleStockHistoryTable: React.FunctionComponent<Props> = ({
  articleId,
}) => {
  return (
    <>
      <SuspenseWrapper
        skeleton={
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        }
      >
        <HistoryTable articleId={articleId} />
      </SuspenseWrapper>
    </>
  );
};

const HistoryTable: React.FunctionComponent<Props> = ({ articleId }) => {
  const { data: history } = useSuspendedArticleInventoryHistory(articleId);

  const [page, setPage] = useState(0);

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Updated</TableCell>
              <TableCell>Stock level</TableCell>
              <TableCell>Updated by</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history
              .filter((_, i) => {
                const start = page * DEFAULT_PAGE_SIZE;
                if (i < start) {
                  return false;
                }
                const end = start + DEFAULT_PAGE_SIZE;
                if (i > end) {
                  return false;
                }
                return true;
              })
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <FormattedDate date={row.created} />
                  </TableCell>
                  <TableCell>{row.stock}</TableCell>
                  <TableCell>{row.updatedBy}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={history.length}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        page={page}
        onPageChange={(_, page) => {
          setPage(page);
        }}
      />
    </>
  );
};
