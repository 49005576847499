import { FunctionComponent, useCallback, useMemo } from "react";
import { QRCode } from "react-qrcode-logo";
import {
  Box,
  Button,
  Grid2 as Grid,
  Paper,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { UserInvite } from "../../../api/UsersAPI";
import ShareIcon from "@mui/icons-material/Share";
import { generatePath } from "react-router";
import { REGISTER_PAGE_ROUTE } from "../../Auth/RegisterPage";
import { MuiLink } from "../../../components/MuiLink";
import { useBreakpointDown } from "../../../hooks/useBreakpoint";

interface Props {
  invite: UserInvite | undefined;
  onClose: () => void;
}

const Puller = () => {
  return (
    <Box
      sx={{
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
      }}
    />
  );
};

const drawerBleeding = 56;

export const UserInviteSheet: FunctionComponent<Props> = ({
  invite,
  onClose,
}) => {
  const isOpen = typeof invite !== "undefined";

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={() => {
        // do nothing
      }}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Puller />
        <Box sx={{ p: 2 }}>
          <InnerSheet {...{ onClose, invite }} />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

const InnerSheet: FunctionComponent<Props> = ({ invite }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isMobile = useBreakpointDown("sm");

  const url = useMemo(() => {
    if (!invite) {
      return "";
    }

    return (
      window.location.origin +
      generatePath(REGISTER_PAGE_ROUTE, { inviteId: invite?.id })
    );
  }, [invite]);

  const share = useCallback(async () => {
    try {
      await navigator.share({
        title: "Rainbow Candy",
        text: "You've been invited to join Rainbow Candy, visit the link to set up your access.",
        url,
      });
    } catch (err) {
      console.log(err);
    }
  }, [url]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        pt: 3,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
          size={{
            xs: 12,
            sm: 12,
          }}
        >
          <Paper
            sx={{
              background: (theme) => theme.palette.common.white,
              canvas: {
                display: "block",
              },
              maxWidth: 400,
            }}
            elevation={0}
          >
            <QRCode
              qrStyle="dots"
              value={url}
              size={isMobile ? 200 : 300}
              fgColor={palette.common.black}
              bgColor="transparent"
              eyeColor={{
                inner: palette.common.black,
                outer: palette.secondary.dark,
              }}
              eyeRadius={[
                [30, 30, 10, 30],
                [30, 30, 30, 10],
                [30, 10, 30, 30],
              ]}
            />
          </Paper>
        </Grid>
        <Grid
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
          size={{
            xs: 12,
            sm: 12,
          }}
        >
          <MuiLink
            align="center"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ wordBreak: "break-word" }}
          >
            {url}
          </MuiLink>
        </Grid>
        <Grid size={12}>
          <Button fullWidth type="submit" onClick={share} color="secondary">
            <ShareIcon sx={{ mr: 1 }} />
            {t("Share")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
