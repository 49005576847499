import { AtomEffect, atom, selector } from "recoil";
import { ArticleId, CandyType } from "../api/CandyAPI";

const localStorageEffect =
  (key: string): AtomEffect<StockList> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: StockList, _, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const updateTimestamps =
  (): AtomEffect<StockList> =>
  ({ setSelf, onSet }) => {
    onSet((newValue: StockList) => {
      if (newValue.items.length === 0) {
        setSelf({
          ...newValue,
          lastUpdated: undefined,
        });
      }
      setSelf({
        ...newValue,
        lastUpdated: new Date().toISOString(),
      });
    });
  };

export interface PickItem {
  articleId: ArticleId;
  ean: string;
  displayName: string;
  candyType?: CandyType;
  quantity: number;
  updated: number;
}

interface StockList {
  lastUpdated: string | undefined;
  items: PickItem[];
}

const defaultState = (): StockList => ({
  lastUpdated: undefined,
  items: [],
});

const STATE_VERSION = "2";
const STATE_KEY = `stock-state-${STATE_VERSION}`;

export const stockState = atom<StockList>({
  key: STATE_KEY,
  default:
    (localStorage.getItem(STATE_KEY) as unknown as StockList) || defaultState(),
  effects: [updateTimestamps(), localStorageEffect(STATE_KEY)],
});

// export const pickArticleSelector = selectorFamily({
//   key: "pickArticleSelector",
//   get:
//     (articleId: ArticleId | undefined) =>
//     ({ get }) => {
//       return get(stockState).items.find((item) => item.articleId === articleId);
//     },
// });

export const stockListCountSelector = selector({
  key: "stockListCountSelector",
  get: ({ get }) => {
    return get(stockState).items.reduce((acc, item) => acc + item.quantity, 0);
  },
});
