import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useReconcileInventoryArticleReduce } from "../hooks/useReconcileInventoryArticleReduce";
import { InventoryReconciliationArticle } from "../../../../../api/InventoryAPI";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  article?: InventoryReconciliationArticle;
}

export const ConfirmReconcileDialog = ({ isOpen, onClose, article }: Props) => {
  const { t } = useTranslation();

  const { mutate: doReduce } = useReconcileInventoryArticleReduce();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("Alter article")}</DialogTitle>

      <DialogContent>
        {t("Make changes to {{ article }}?", { article: article?.displayName })}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={onClose} color="inherit">
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => article && doReduce(article.articleId)}
          variant="contained"
          color="secondary"
        >
          {t("Reduce by one")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
