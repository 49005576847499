import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { registerSW } from "virtual:pwa-register";
import "./i18n.ts";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";

if (import.meta.env.PROD) {
  H.init("6glr507g", {
    serviceName: "frontend-app",
    tracingOrigins: true,
    environment: import.meta.env.PROD ? "production" : "development",
    // networkRecording: {
    //   enabled: true,
    //   recordHeadersAndBody: true,
    //   urlBlocklist: [
    //     // insert full or partial urls that you don't want to record here
    //     // Out of the box, Highlight will not record these URLs (they can be safely removed):
    //     "https://www.googleapis.com/identitytoolkit",
    //     "https://securetoken.googleapis.com",
    //   ],
    // },
  });
}

const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm("New version of the app available. Reload?")) {
      updateSW(true);
    }
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary showDialog={false}>
    <App />
  </ErrorBoundary>
);
