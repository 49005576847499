import { useSetRecoilState } from "recoil";
import { pickState } from "../state/pickState";

export const useResetPickList = () => {
  const setPickState = useSetRecoilState(pickState);

  return () => {
    setPickState((oldState) => {
      return {
        ...oldState,
        items: [],
      };
    });
  };
};
