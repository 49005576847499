import { CandyArticle, UTCDate } from "./CandyAPI";
import { InventoryItem } from "./InventoryAPI";
import { API, RequiredQueryOptions } from "./network/API";

export interface ScanArticle
  extends Pick<
    CandyArticle,
    | "articleId"
    | "ean"
    | "displayName"
    | "candyType"
    | "packageSize"
    | "disabled"
  > {
  price: number;
  inventory: InventoryItem;
  translation: {
    displayName: string;
  };
  order: {
    pendingOrder: number;
    requestedOrder: UTCDate;
    lastDelivery: UTCDate;
  };
}

const QUERY_KEY = "scan";

export const scanAPI = {
  QUERY_KEY,
  fetchArticle: (ean: string): RequiredQueryOptions<ScanArticle> => ({
    queryKey: [QUERY_KEY, "articles", ean],
    queryFn: () => API.get<ScanArticle>(`/scan?ean=${ean}`),
  }),
};
