import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OrderItem, orderAPI } from "../api/OrderAPI";

const queryKey = orderAPI.fetchNextOrder().queryKey;

type QueryDataType =
  | Awaited<ReturnType<ReturnType<typeof orderAPI.fetchNextOrder>["queryFn"]>>
  | undefined;

export const useAddToOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderAPI.addToNextOrder,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey });

      const previousItems = queryClient.getQueryData<OrderItem[]>(queryKey);

      queryClient.setQueryData<QueryDataType>(queryKey, (old) => {
        if (!old) {
          return undefined;
        }

        return old.map((i) => {
          if (i.articleId === data.articleId) {
            return { ...i, count: data.count };
          }
          return i;
        });
      });

      return { previousItems };
    },
    onSuccess: (_, articleId) => {
      queryClient.invalidateQueries({ queryKey: [articleId] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousItems);
    },
  });
};
