import {
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { ArticleFilter, CandyRow, CandyType } from "../../../api/CandyAPI";
import { SortDirection } from "../../../api/Page";
import { ArticleStatus } from "../../../components/ArticleStatus";
import { CandyPage } from "../../../components/layout/CandyPage";
import { CandyTypeChip } from "../../../components/CandyTypeChip";
import { MuiLink } from "../../../components/MuiLink";
import { UnitPrice } from "../../../components/UnitPrice";
import { useSuspendedArticles } from "../../../hooks/useArticles";
import { ARTICLE_PAGE_ROUTE } from "../ArticlePage/ArticlePage";
import { filterToParams } from "../../../utils";
import { AppTopBarActions } from "../../../components/layout/AppTopBarActions";
import { ArticleFilters } from "./components/ArticleFilters";
import { SortedTableCell } from "../../../components/table/SortedTableCell";

export const ARTICLE_LIST_PAGE_ROUTE = "/articles";

export const ARTICLE_LIST_PAGE_ROUTE_WITH_FILTER = (
  filter: ArticleFilter,
  sortField?: keyof CandyRow
) => {
  const params = new URLSearchParams();
  Object.entries({ ...filter, sortField }).forEach(([key, value]) => {
    if (value) {
      params.set(key, value.toString());
    }
  });
  return `${ARTICLE_LIST_PAGE_ROUTE}?${params.toString()}`;
};

const DEFAULT_PAGE_SIZE = 50;

export const ArticleListPage = () => {
  return (
    <CandyPage
      fullHeight
      fullScreen
      title="All candy"
      skeleton={<PageSkeleton />}
    >
      <Inner />
    </CandyPage>
  );
};

const parseTypes = (types: string | null): CandyType[] | undefined => {
  if (types === null || types.length === 0 || !types) {
    return undefined;
  }

  return types.split(",").filter((t) => t in CandyType) as CandyType[];
};

const Inner = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [isPendingTransition, startTransition] = useTransition();

  const page = searchParams.get("page") || "0";
  const size = searchParams.get("size") || DEFAULT_PAGE_SIZE.toString();

  const sortfield: keyof CandyRow =
    (searchParams.get("sortField") as keyof CandyRow) || "created";

  const sortDirection = SortDirection.DESC;

  const filter: ArticleFilter = {
    types: parseTypes(searchParams.get("types")),
  };

  const { data: result } = useSuspendedArticles(
    parseInt(page),
    parseInt(size),
    sortDirection,
    sortfield,
    filter
  );

  const handleFilterChange = (filter: ArticleFilter = {}) => {
    startTransition(() => {
      setSearchParams({
        page: searchParams.get("page") || "0",
        size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
        ...filterToParams(filter, sortfield, sortDirection),
      });
    });
  };

  const handleSortChange = (field: keyof CandyRow) => {
    if (sortfield === field) {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(
            filter,
            sortfield,
            sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
          ),
        });
      });
    } else {
      startTransition(() => {
        setSearchParams({
          page: searchParams.get("page") || "0",
          size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
          ...filterToParams(filter, sortfield, SortDirection.DESC),
        });
      });
    }
  };

  return (
    <>
      <AppTopBarActions>
        <ArticleFilters filter={filter} onChange={handleFilterChange} />
      </AppTopBarActions>

      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <SortedTableCell
                field="articleId"
                currentSortField={sortfield}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                {t("Article")}
              </SortedTableCell>

              <SortedTableCell
                field="displayName"
                currentSortField={sortfield}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                {t("Name")}
              </SortedTableCell>

              <TableCell>{t("Unit price")}</TableCell>

              <SortedTableCell
                field="candyType"
                currentSortField={sortfield}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
              >
                {t("Type")}
              </SortedTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              opacity: isPendingTransition ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
            }}
          >
            {result?.content?.map((candy) => (
              <TableRow key={candy.articleId}>
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={generatePath(ARTICLE_PAGE_ROUTE, {
                      articleId: candy.articleId,
                    })}
                  >
                    {candy.articleId}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  <ArticleStatus
                    disabled={candy.disabled}
                    // TODO: add prop when in backend
                    untranslated={
                      candy.translationName === "" ||
                      typeof candy.translationName === "undefined"
                    }
                    // translated={!candy.translationName}
                    onOrder={candy.pendingOrder}
                  >
                    {candy.displayName}
                  </ArticleStatus>
                </TableCell>
                <TableCell>
                  <UnitPrice
                    price={candy.kgPrice}
                    candyType={candy.candyType}
                  />
                </TableCell>
                <TableCell>
                  <CandyTypeChip candyType={candy.candyType} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, DEFAULT_PAGE_SIZE, 100]}
        component="div"
        count={result?.totalElements || 0}
        rowsPerPage={result?.size || DEFAULT_PAGE_SIZE}
        page={result?.number || 0}
        onPageChange={(_, page) => {
          startTransition(() => {
            setSearchParams({
              size: searchParams.get("size") || DEFAULT_PAGE_SIZE.toString(),
              page: page.toString(),
              ...filterToParams(filter, sortfield, sortDirection),
            });
          });
        }}
        onRowsPerPageChange={(event) => {
          startTransition(() => {
            setSearchParams({
              page: searchParams.get("page") || "0",
              size: event.target.value,
              ...filterToParams(filter, sortfield, sortDirection),
            });
          });
        }}
      />
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 10,
            sm: 11,
          }}
        >
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 1,
          }}
        >
          <Skeleton variant="rounded" height={60} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
