import { useMemo } from "react";

const canVibrate = window.navigator.vibrate as unknown as boolean;

export enum FeedbackSound {
  SUCCESS = "/blip.mp3",
  ERROR = "/bruh.mp3",
}

interface FeedbackOptions {
  sound?: FeedbackSound;
  vibration?: number;
}

export const useFeedback = () => {
  return useMemo(
    () =>
      ({
        sound = FeedbackSound.SUCCESS,
        vibration = 100,
      }: FeedbackOptions = {}) => {
        new Audio(sound).play();
        if (canVibrate) {
          navigator.vibrate(vibration);
        }
      },
    []
  );
};
