import { Stepper, Step, StepLabel, Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Order } from "../../../../api/OrderAPI";
import { FormattedDate } from "../../../../components/FormattedDate/FormattedDate";
import { useTranslation } from "react-i18next";

interface Props {
  order: Order;
}

export const OrderStatusStepper: FunctionComponent<Props> = ({ order }) => {
  const { t } = useTranslation();
  const { ordered, shipped, delivered, confirmed } = order;

  return (
    <Stepper alternativeLabel>
      <Step completed={!!ordered}>
        <StepLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="inherit">{t("Ordered")}</Typography>
            {ordered && (
              <Typography variant="caption" color="text.secondary">
                <FormattedDate value={ordered} />
              </Typography>
            )}
          </Box>
        </StepLabel>
      </Step>
      <Step completed={!!shipped} active={!!ordered}>
        <StepLabel>
          <Typography variant="inherit">{t("Shipped")}</Typography>
          {shipped && (
            <Typography variant="caption" color="text.secondary">
              <FormattedDate value={shipped} />
            </Typography>
          )}
        </StepLabel>
      </Step>
      <Step completed={!!delivered} active={!!shipped}>
        <StepLabel>
          <Typography variant="inherit">{t("Delivered")}</Typography>
          {delivered && (
            <Typography variant="caption" color="text.secondary">
              <FormattedDate value={delivered} />
            </Typography>
          )}
        </StepLabel>
      </Step>
      <Step completed={!!confirmed} active={!!delivered}>
        <StepLabel>
          <Typography variant="inherit">{t("Confirmed")}</Typography>
          {confirmed && (
            <Typography variant="caption" color="text.secondary">
              <FormattedDate value={confirmed} />
            </Typography>
          )}
        </StepLabel>
      </Step>
    </Stepper>
  );
};
