import { FunctionComponent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { APP_TOP_BAR_ID, AppTopBarTitle } from "./AppTopBar";

interface Props {
  title: string;
}

export const Helmet: FunctionComponent<Props> = ({ title }) => {
  const [portal, setPortal] = useState<Element | null>(() =>
    document.getElementById(APP_TOP_BAR_ID)
  );

  useEffect(() => {
    document.title = title + " - Rainbow Candy";
  }, [title]);

  useEffect(() => {
    setPortal(document.getElementById(APP_TOP_BAR_ID));
  }, []);

  if (!title || !portal) {
    return null;
  }

  return createPortal(<AppTopBarTitle {...{ title }} />, portal);
};
