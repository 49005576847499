import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import TranslateIcon from "@mui/icons-material/Translate";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import { Document, Font, PDFViewer, Page, View } from "@react-pdf/renderer";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { CandySearch } from "../api/CandyAPI";
import {
  PrintQueueItem,
  printQueue,
  useAddToPrint,
  useClearPrintQueue,
  useFlagAsPrinted,
  useRemoveFromPrint,
} from "../api/PrintQueueAPI";
import { CandyPrintRow } from "../components/CandyPrintRow/CandyPrintRow";
import { PiecePricePrint } from "../components/CandyPrintRow/PiecePricePrint";
import { ConfirmButton } from "../components/ConfirmButton";
import { CandyPage } from "../components/layout/CandyPage";
import { MuiIconButtonLink } from "../components/MuiLink";
import { Search } from "../components/Search";
import PublicSansBold from "../fonts/PublicSans-Bold.ttf";
import PublicSansItalic from "../fonts/PublicSans-Italic.ttf";
import PublicSansRegular from "../fonts/PublicSans-Regular.ttf";
import { ARTICLE_PAGE_ROUTE } from "./Article/ArticlePage/ArticlePage";
import { TRANSLATION_ARTICLE_PAGE_ROUTE } from "./Translation/TranslationArticlePage/TranslationArticlePage";
import { EditExpiryDialog } from "../components/EditExpiryDialog";
import { useSnackBar } from "../hooks/useSnackbar";

export const LABEL_PRINT_PAGE_BASE_ROUTE = "/labels";

const renderPdf = (candy: PrintQueueItem[], pieces: PrintQueueItem[]) => {
  return (
    <Document>
      <Page size={"A4"} orientation="landscape">
        {candy.map((item, idx) => {
          return (
            <CandyPrintRow
              key={item.id}
              item={item}
              first={idx === 0 || idx % 4 === 0}
            />
          );
        })}
        {pieces ? (
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {pieces.map((item) => {
              return <PiecePricePrint key={item.id} item={item} />;
            })}
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export const LabelPrintPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const {
    mutate: removeFromPrint,
    isPending: isRemoving,
    variables: removingArticleId,
  } = useRemoveFromPrint();
  const { mutate: addToPrint } = useAddToPrint(() => {
    showSnackBar(t("Can not print article"), "error");
  });
  const { mutate: clearPrintQueue } = useClearPrintQueue();
  const { mutate: flagAllAsPrinted } = useFlagAsPrinted();

  Font.register({
    family: "PublicSans",
    fonts: [
      { src: PublicSansRegular },
      { src: PublicSansBold, fontWeight: "bold" },
      {
        src: PublicSansItalic,
        fontWeight: "normal",
        fontStyle: "italic",
      },
    ],
  });
  const { data: queue } = useQuery({
    ...printQueue.fetchQueue(),
  });

  const handleSearch = (result: CandySearch[]) => {
    if (result.length === 1) {
      //addToPrint(result[0].articleId);
    }
  };

  const deleteLabel = (id: number) => {
    removeFromPrint(id);
  };

  const candy = queue?.filter((item) => !item.pieceArticle) || [];
  const pieces = queue?.filter((item) => item.pieceArticle) || [];
  return (
    <CandyPage title="Print">
      <div>
        <Search
          onSearch={handleSearch}
          closeOnSelect={true}
          onClick={(item) => {
            const alreadyInQueue = queue?.find(
              (i) => i.articleId === item.articleId
            );
            if (!alreadyInQueue) {
              addToPrint(item.articleId);
            }
          }}
        />
        <Divider variant="middle" sx={{ mx: 2 }} />
        <Box sx={{ m: 2 }}>
          {queue && queue.length > 0 ? (
            <>
              <ConfirmButton
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{
                  mb: 2,
                }}
                confirmText={t("Are you sure you want to clear the list?")}
                onConfirm={clearPrintQueue}
              >
                {t("Clear list")}
              </ConfirmButton>
              <ConfirmButton
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mb: 2,
                }}
                confirmText={t("Have all items been printed?")}
                onConfirm={flagAllAsPrinted}
              >
                {t("Mark as printed")}
              </ConfirmButton>
              <Paper elevation={2}>
                <List dense>
                  {queue?.map((item) => (
                    <ListItem
                      key={item.id}
                      secondaryAction={
                        <>
                          <span style={{ fontSize: "12px" }}>
                            {item.expiry}
                          </span>
                          <EditExpiryDialog articleId={item.articleId} />
                          <MuiIconButtonLink
                            to={generatePath(ARTICLE_PAGE_ROUTE, {
                              articleId: item.articleId,
                            })}
                          >
                            <VisibilityRoundedIcon />
                          </MuiIconButtonLink>
                          <MuiIconButtonLink
                            to={generatePath(TRANSLATION_ARTICLE_PAGE_ROUTE, {
                              articleId: item.articleId,
                            })}
                          >
                            <TranslateIcon />
                          </MuiIconButtonLink>
                          <IconButton
                            color="error"
                            onClick={() => deleteLabel(item.id)}
                            disabled={
                              isRemoving && removingArticleId === item.id
                            }
                          >
                            {isRemoving && removingArticleId === item.id ? (
                              <CircularProgress size={24} />
                            ) : (
                              <DeleteIcon />
                            )}
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        {item.pieceArticle ||
                        (item.ingredients &&
                          Object.keys(item.nutrients).length > 0) ? (
                          <Avatar>
                            <ArticleIcon />
                          </Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: (theme) => theme.palette.warning.main,
                            }}
                          >
                            <ReportProblemIcon />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.displayName}
                        secondary={item.ean}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </>
          ) : (
            <Paper elevation={4}>
              <List dense>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ArticleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t("No labels selected")}
                    secondary={t(
                      "Search for a product to add it to the print list"
                    )}
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Box>
      </div>

      <Divider
        variant="middle"
        sx={{
          m: 2,
        }}
      />

      <div className="pdf-wrapper">
        <PDFViewer>{renderPdf(candy, pieces)}</PDFViewer>
      </div>
    </CandyPage>
  );
};
