import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ReconciliationEntry } from "../../../../api/OrderAPI";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const SCAN_ALERT_DURATION = 2500;

interface Props {
  entry?: ReconciliationEntry;
}

export const RecentlyScanned: FunctionComponent<Props> = ({ entry }) => {
  const { t } = useTranslation();

  return (
    <>
      <AnimatePresence initial={false}>
        {entry && (
          <Box
            component={motion.div}
            sx={{
              background: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.success.dark + "CC"
                  : theme.palette.success.light + "CC",
              position: "absolute",
              top: "0px",
              width: "100%",
              zIndex: 200,
              display: "flex",
              alignItems: "center",
            }}
            key={entry.articleId}
            initial={{
              opacity: 0,
              height: "100%",
            }}
            animate={{
              opacity: 1,
              height: "auto",
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 17,
            }}
          >
            <Alert
              severity={entry.articleId ? "success" : "warning"}
              variant="filled"
              sx={{
                background: "transparent",
                width: "100%",
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.text.primary
                    : theme.palette.success.contrastText,
              }}
              icon={
                <Box
                  component={motion.div}
                  key={entry.articleId + "-" + entry.delivered}
                  layoutId={entry.articleId + "-" + entry.delivered}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  sx={{
                    transformOrigin: "center",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 10,
                  }}
                >
                  <TaskAltIcon />
                </Box>
              }
              action={
                <AlertTitle sx={{ p: 2, flexShrink: 0 }}>
                  <motion.span
                    key={entry.articleId + "-" + entry.delivered}
                    layoutId={entry.articleId + "-" + entry.delivered}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 200,
                      damping: 10,
                    }}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {entry.delivered}
                  </motion.span>{" "}
                  / <span>{entry.confirmed}</span>
                </AlertTitle>
              }
            >
              <AlertTitle>{t("Marked as delivered")}</AlertTitle>
              <Typography variant="body2" noWrap>
                {entry.displayName}
              </Typography>
            </Alert>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};
