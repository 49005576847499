import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OrderItem, orderAPI } from "../api/OrderAPI";

const queryKey = orderAPI.fetchNextOrder().queryKey;

type QueryDataType =
  | Awaited<ReturnType<ReturnType<typeof orderAPI.fetchNextOrder>["queryFn"]>>
  | undefined;

export const useRemoveFromOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderAPI.deleteFromNextOrder,
    onMutate: async (articleId) => {
      await queryClient.cancelQueries({ queryKey });

      const previousItems = queryClient.getQueryData<OrderItem[]>(queryKey);

      queryClient.setQueryData<QueryDataType>(queryKey, (old) => {
        if (!old) {
          return undefined;
        }

        return old.filter((i) => i.articleId !== articleId);
      });

      return { previousItems };
    },
    onSuccess: (_, articleId) => {
      queryClient.invalidateQueries({ queryKey: [articleId] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousItems);
    },
  });
};
