import { LoadingButton } from "@mui/lab";

import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleId } from "../api/CandyAPI";
import { inventoryAPI } from "../api/InventoryAPI";
import { printQueue } from "../api/PrintQueueAPI";
import { useBreakpointDown } from "../hooks/useBreakpoint";
import { useSnackBar } from "../hooks/useSnackbar";

interface Props {
  articleId: ArticleId;
  expiry?: string;
}

function resolveExpiry(expiry?: string) {
  if (expiry) {
    return expiry;
  }
  const exp = DateTime.now().toISODate();
  if (!exp) {
    return undefined;
  }
  return exp;
}

export const EditExpiryDialog: FunctionComponent<Props> = ({
  expiry,
  articleId,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [internalExpiry, setInternalExpiry] = useState(resolveExpiry(expiry));
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const sm = useBreakpointDown("sm");
  const queryClient = useQueryClient();

  const handleOk = () => {
    if (!internalExpiry) {
      return;
    }
    setLoading(true);
    inventoryAPI
      .updateExpiry(articleId, internalExpiry)
      .then(() => {
        showSnackBar(t("Updated expiry of article"), "success");
        queryClient.invalidateQueries({
          queryKey: inventoryAPI.fetchInventory(articleId).queryKey,
        });
        queryClient.invalidateQueries({
          queryKey: [printQueue.QUERY_KEY],
        });
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <EditCalendarIcon color="info" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={sm ? true : false}
      >
        <DialogTitle>{t("Update expiry")}</DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Box>
            {/* <DatePicker
              value={internalExpiry}
              onChange={(newValue: string) => setInternalExpiry(newValue)}
            /> */}

            <TextField
              //label="Next appointment"
              type="date"
              value={internalExpiry}
              onChange={(e) => setInternalExpiry(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} fullWidth color="inherit">
            {t("Cancel")}
          </Button>
          <LoadingButton
            onClick={handleOk}
            variant="contained"
            fullWidth
            color="primary"
            loading={loading}
          >
            {t("Update")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
