import {
  Box,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReconcileInventoryArticle } from "./hooks/useReconcileInventoryArticle";
import { RecentlyScanned } from "./components/RecentlyScanned";
import { motion, useSpring } from "framer-motion";
import { ConfirmReconcileDialog } from "./components/ConfirmReconcileDialog";
import { InventoryReconcilationDoneButton } from "./components/InventoryReconcilationDoneButton";
import { useSuspenseQuery } from "@tanstack/react-query";
import { InventoryReconciliationContextMenu } from "./components/InventoryReconciliationContextMenu";
import {
  InventoryReconciliationArticle,
  inventoryAPI,
} from "../../../../api/InventoryAPI";
import { DevButtons } from "../../../../components/DevButtons";
import { MemoizedNewScanner } from "../../../../components/Scanner/NewScanner";
import { AppTopBarActions } from "../../../../components/layout/AppTopBarActions";
import { CandyPage } from "../../../../components/layout/CandyPage";
import { useFeedback, FeedbackSound } from "../../../../hooks/useFeedback";
import { useSnackBar } from "../../../../hooks/useSnackbar";
import { MuiLink } from "../../../../components/MuiLink";
import { ARTICLE_PAGE_ROUTE } from "../../../Article/ArticlePage/ArticlePage";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

export const INVENTORY_RECONCILIATION_PAGE_ROUTE =
  "/inventory/reconciliation/current";

export const InventoryReconciliationPage: React.FunctionComponent = () => {
  return (
    <CandyPage fullHeight title="Reconciliation" skeleton={<PageSkeleton />}>
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const { showSnackBar } = useSnackBar();
  const feedback = useFeedback();
  const { t } = useTranslation();
  const theme = useTheme();
  const [confirmItem, setConfirmItem] =
    useState<InventoryReconciliationArticle>();
  const blocked = useRef(false);
  const cameraOpacity = useSpring(0);

  const { data: items } = useSuspenseQuery(
    inventoryAPI.getCurrentReconciliation()
  );

  const sortedItems = useMemo(() => {
    // sort by update property
    return items.sort(
      (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
    );
  }, [items]);

  const { mutate: reconcileArticle, data: reconciledData } =
    useReconcileInventoryArticle();

  const latestItem = useMemo(() => {
    return reconciledData;
  }, [reconciledData]);

  const doReconcileArticle = useCallback(
    (ean: string) => {
      if (blocked.current) {
        return;
      }
      blocked.current = true;
      cameraOpacity.set(0.75);
      reconcileArticle(ean, {
        onSuccess: () => {
          feedback();
        },
        onError: () => {
          // TODO: error feedback, another sound?
          feedback({ sound: FeedbackSound.ERROR });
          // TODO: nicer presentation, not ean
          showSnackBar(
            t("{{article}} not recognized or not found, try again", {
              article: ean,
            }),
            "warning"
          );
        },
        onSettled: () => {
          setTimeout(() => {
            blocked.current = false;
            cameraOpacity.set(0);
          }, 2000);
        },
      });
    },
    [cameraOpacity, feedback, reconcileArticle, showSnackBar, t]
  );

  return (
    <>
      <AppTopBarActions>
        <InventoryReconcilationDoneButton />
        <InventoryReconciliationContextMenu />
      </AppTopBarActions>
      <ConfirmReconcileDialog
        article={confirmItem}
        onClose={() => setConfirmItem(undefined)}
        isOpen={!!confirmItem}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            overflow: "hidden",
            position: "relative",
            background: (theme) => theme.palette.action.focus,
          }}
        >
          <RecentlyScanned entry={latestItem} />
          <DevButtons onScanSuccess={doReconcileArticle} position="bottom" />
          {/* <Scanner
              onScanSuccess={doReconcileArticle}
              onScanError={() => {
                // TODO: Show error
              }}
              style={{
                flex: 1,
                objectFit: "cover",
              }}
            /> */}
          <MemoizedNewScanner onScan={doReconcileArticle} scanDelay={500} />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
            style={{
              opacity: cameraOpacity,
            }}
            component={motion.div}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            position: "relative",
            display: "flex",
            maxHeight: "60%",
            "&:after": (theme) => ({
              pointerEvents: "none",
              content: '""',
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              height: 40,
              background: `linear-gradient(to top, ${theme.palette.background.paper} -25%, transparent 100%)`,
            }),
          }}
        >
          <TableContainer
            sx={{
              maxWidth: "100%",
              position: "relative",
              paddingBottom: 8,
              flex: 1,
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    width={1}
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("ID")}
                  </TableCell>
                  <TableCell>{t("Article")}</TableCell>
                  <TableCell align="right">{t("Quantity")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems.map((row) => (
                  <TableRow
                    key={row.articleId}
                    hover
                    component={motion.tr}
                    layout
                    layoutId={`row-${row.articleId}`}
                    animate={{
                      background:
                        latestItem?.articleId === row.articleId
                          ? [
                              "#00000000",
                              theme.palette.success.light + "66",
                              "#00000000",
                            ]
                          : "#00000000",
                      transition: {
                        duration: 0.75,
                      },
                    }}
                  >
                    <TableCell
                      width={1}
                      size="medium"
                      onClick={() => setConfirmItem(row)}
                    >
                      <MuiLink
                        component={Link}
                        to={generatePath(ARTICLE_PAGE_ROUTE, {
                          articleId: row.articleId,
                        })}
                      >
                        {row.articleId}
                      </MuiLink>
                    </TableCell>
                    <TableCell onClick={() => setConfirmItem(row)}>
                      <Box>{row.displayName}</Box>
                    </TableCell>
                    <TableCell
                      onClick={() => setConfirmItem(row)}
                      align="right"
                    >
                      {row.count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid size={12}>
        <Skeleton variant="rounded" height={40} />
      </Grid>
      {[...Array(20)].map((_, i) => (
        <Grid key={i} size={12}>
          <Skeleton variant="rounded" height={50} />
        </Grid>
      ))}
    </Grid>
  );
};
