import React, { Suspense } from "react";
import { ErrorBoundary as Boundary } from "@highlight-run/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { Typography, Paper, Button, Skeleton, Box } from "@mui/material";
import { t } from "i18next";

interface Props {
  children: React.ReactNode;
  skeleton?: React.ReactNode;
  errorTitle?: string | React.ReactNode;
  errorContent?: React.ReactNode;
  retryText?: string;
  locationBased?: boolean;
}

export const ErrorBoundary: React.FunctionComponent<Props> = ({
  children,
  skeleton,
  errorTitle,
  errorContent,
  retryText,
  locationBased = false,
}) => {
  const location = useLocation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <>
          <Boundary
            key={locationBased ? location.pathname : undefined}
            onReset={reset}
            showDialog={false}
            fallback={({ error, resetError }) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                  p: 2,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {errorTitle || t("Oops, something went wrong")}
                </Typography>
                {errorContent || (
                  <Typography variant="subtitle1" gutterBottom>
                    {t("Couldn't load the page, try again later")}
                  </Typography>
                )}
                {/* if development */}
                {import.meta.env.DEV && (
                  <Paper sx={{ p: 2 }}>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        margin: 0,
                        maxHeight: "40vh",
                        overflowY: "auto",
                      }}
                    >
                      {error.stack}
                    </pre>
                  </Paper>
                )}

                <Button
                  onClick={resetError}
                  variant="outlined"
                  size="large"
                  sx={{ mt: 2 }}
                >
                  {retryText || t("Try again")}
                </Button>
              </Box>
            )}
          >
            <Suspense
              fallback={
                <Box sx={{ p: 2 }}>
                  {skeleton || (
                    <>
                      <h1>
                        <Skeleton variant="text" />
                      </h1>
                      <div>
                        <Skeleton variant="rounded" />
                        <Skeleton variant="rounded" height={120} />
                      </div>
                    </>
                  )}
                </Box>
              }
            >
              {children}
            </Suspense>
          </Boundary>
        </>
      )}
    </QueryErrorResetBoundary>
  );
};
