import {
  Box,
  Grid2 as Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useSuspenseQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { AvailableAtProvider, inventoryAPI } from "../../../api/InventoryAPI";
import { orderAPI } from "../../../api/OrderAPI";
import { AddToOrderDialog } from "../../../components/AddToOrderDialog";
import { CandyPage } from "../../../components/layout/CandyPage";
import { MuiLink } from "../../../components/MuiLink";
import { ARTICLE_PAGE_ROUTE } from "../../Article/ArticlePage/ArticlePage";
import { ArticleId } from "../../../api/CandyAPI";
import { FunctionComponent, useMemo, useState } from "react";
import { visuallyHidden } from "@mui/utils";

export const INVENTORY_NO_STOCK_PAGE_ROUTE = "/inventory/no-stock";

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (a[orderBy] === undefined) {
    return 1;
  }
  if (b[orderBy] === undefined) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof AvailableAtProvider>(
  order: Order,
  orderBy: Key
): (a: AvailableAtProvider, b: AvailableAtProvider) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const providerStockPrint = (providerStock: number) => {
  if (providerStock < 0) {
    return "0";
  }
  if (providerStock > 100) {
    return "> 100";
  }
  return providerStock;
};

interface SortLabelProps {
  label: string;
  sortKey: keyof AvailableAtProvider;
  orderBy: string;
  direction: Order;
  updateSort: (property: keyof AvailableAtProvider) => void;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

const SortLabel: FunctionComponent<SortLabelProps> = ({
  label,
  sortKey,
  orderBy,
  direction,
  updateSort,
  align = "inherit",
}) => {
  return (
    <TableCell
      align={align}
      sortDirection={orderBy === sortKey ? direction : false}
    >
      <TableSortLabel
        active={orderBy === sortKey}
        direction={orderBy === sortKey ? direction : "asc"}
        onClick={() => updateSort(sortKey)}
      >
        {label}
        {orderBy === sortKey ? (
          <Box component="span" sx={visuallyHidden}>
            {direction === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export const InventoryNoStockPage: React.FunctionComponent = () => {
  return (
    <CandyPage
      fullHeight
      title="Inventory - Available for purchase"
      skeleton={<PageSkeleton />}
    >
      <Inner />
    </CandyPage>
  );
};

const Inner: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] =
    useState<keyof AvailableAtProvider>("articleId");

  const updateSort = (property: keyof AvailableAtProvider) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [{ data: stockAvailable }, { data: pendingOrders }] =
    useSuspenseQueries({
      queries: [
        inventoryAPI.loadNotInInventoryButAvailableWithProvider(),
        orderAPI.fetchNextOrder(),
      ],
    });

  const pendingOrdersMap: Record<ArticleId, number> = useMemo(() => {
    return pendingOrders?.reduce((acc: Record<ArticleId, number>, item) => {
      acc[item.articleId] = item.count;
      return acc;
    }, {});
  }, [pendingOrders]);

  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("Order")}</TableCell>
              <SortLabel
                label={t("Article")}
                sortKey="displayName"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
              />
              <SortLabel
                label={t("Available")}
                sortKey="providerStock"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
              <SortLabel
                label={t("Arrived")}
                sortKey="arrivedInStock"
                direction={order}
                orderBy={orderBy}
                updateSort={updateSort}
                align="right"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {stockAvailable
              ?.sort(getComparator(order, orderBy))
              .map((candy) => (
                <TableRow key={candy.articleId}>
                  <TableCell>
                    <img
                      width={50}
                      loading="lazy"
                      // hidden={noPicture}
                      // onError={() => setNoPicture(true)}
                      // onLoad={() => setNoPicture(false)}
                      src={`https://storage.googleapis.com/candy-pictures/articles/${candy.articleId}.jpg`}
                    />
                  </TableCell>
                  <TableCell>
                    {pendingOrdersMap[candy.articleId]}
                    <AddToOrderDialog
                      displayName={candy.displayName}
                      articleId={candy.articleId}
                      existingOrder={pendingOrdersMap[candy.articleId]}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiLink
                      component={Link}
                      to={generatePath(ARTICLE_PAGE_ROUTE, {
                        articleId: candy.articleId,
                      })}
                    >
                      {candy.displayName}
                    </MuiLink>
                  </TableCell>
                  <TableCell align="right">
                    {providerStockPrint(candy.providerStock)}
                  </TableCell>
                  <TableCell align="right">{candy.arrivedInStock}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(20)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
