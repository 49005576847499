import { TableRow, alpha, TableCell, Box } from "@mui/material";
import { FC, useState } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { OrderedItem, Order } from "../../../../api/OrderAPI";
import { MuiLink } from "../../../../components/MuiLink";
import { ARTICLE_PAGE_ROUTE } from "../../../Article/ArticlePage/ArticlePage";

interface Props {
  item: OrderedItem;
  order: Order;
}

export const OrderItemRow: FC<Props> = ({ item, order }) => {
  const [noPicture, setNoPicture] = useState(false);

  return (
    <TableRow
      key={item.id}
      sx={{
        ...(item.confirmed === 0 && {
          backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.15),
        }),
      }}
    >
      <TableCell sx={{ p: 0.5 }}>
        <Box
          component="img"
          sx={{ display: "block", borderRadius: 1 }}
          width={60}
          loading="lazy"
          hidden={noPicture}
          onError={() => setNoPicture(true)}
          onLoad={() => setNoPicture(false)}
          src={`https://storage.googleapis.com/candy-pictures/articles/${item.articleId}.jpg`}
        />
      </TableCell>
      <TableCell>
        <MuiLink
          component={Link}
          to={generatePath(ARTICLE_PAGE_ROUTE, {
            articleId: item.articleId,
          })}
        >
          {item.articleId}
        </MuiLink>
      </TableCell>
      <TableCell>{item.displayName}</TableCell>
      <TableCell align="right">{item.price}</TableCell>
      <TableCell align="right">{item.ordered}</TableCell>
      <TableCell align="right">{item.confirmed}</TableCell>
      <TableCell align="right">
        {order.delivered ? item.delivered : "-"}
      </TableCell>
    </TableRow>
  );
};
