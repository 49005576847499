import { useRef, useLayoutEffect, useCallback, useEffect } from "react";

const TIMEOUT = 1000;

export const useKeyPress = (
  keys: string[],
  ctrl = false,
  callback: (event: KeyboardEvent) => void,
  node = null
) => {
  const callbackRef = useRef(callback);
  const lastKeyPressTimeRef = useRef(0);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (keys.some((key) => event.key === key)) {
        if (ctrl && !(event.ctrlKey || event.metaKey)) {
          return;
        }
        const currentTime = Date.now();
        if (currentTime - lastKeyPressTimeRef.current < TIMEOUT) {
          return;
        }
        lastKeyPressTimeRef.current = currentTime;
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [ctrl, keys]
  );

  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};
