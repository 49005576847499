import { useQuery } from "@tanstack/react-query";
import { ArticleId, candyAPI } from "../api/CandyAPI";

export const useArticle = (articleId: ArticleId) => {
  return useQuery({
    ...candyAPI.fetchArticle(articleId),
  });
};

export const useOptionalArticle = (articleId?: ArticleId) => {
  return useQuery({
    ...(articleId
      ? candyAPI.fetchArticle(articleId)
      : {
          queryKey: [],
        }),
    enabled: typeof articleId !== "undefined",
  });
};
