import { Box, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { FC, FunctionComponent, useMemo, useState } from "react";
import { ArticleId } from "../api/CandyAPI";
import { inventoryAPI } from "../api/InventoryAPI";
import {
  LineChart,
  areaElementClasses,
  lineElementClasses,
  lineHighlightElementClasses,
  useDrawingArea,
  useYScale,
} from "@mui/x-charts";
import { useQuery } from "@tanstack/react-query";

interface Props {
  articleId: ArticleId;
}

export const ProviderInventoryChart: FunctionComponent<Props> = ({
  articleId,
}) => {
  const theme = useTheme();
  const [toDate] = useState<DateTime>(DateTime.now());
  const [fromDate] = useState<DateTime>(DateTime.now().minus({ months: 6 }));

  const { data } = useQuery(
    inventoryAPI.fetchProviderHistory(
      articleId,
      fromDate.toISODate() as string,
      toDate.toISODate() as string
    )
  );

  const computedData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .map(({ created, count }) => ({
        date: new Date(created.split("T")[0]),
        count,
      }))
      .reverse();
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1, height: "100%" }}>
          <LineChart
            dataset={computedData}
            height={400}
            xAxis={[
              {
                id: "Months",
                dataKey: "date",
                scaleType: "time",
              },
            ]}
            series={[
              {
                id: "Count",
                dataKey: "count",
                type: "line",
                area: true,
                color: theme.palette.primary.main,
                showMark: false,
                connectNulls: true,
              },
            ]}
            sx={{
              [`& .${areaElementClasses.root}`]: {
                fill: "url(#swich-color-id-1)",
              },
              [`& .${lineElementClasses.root}`]: {
                stroke: "url(#swich-color-id-1)",
              },
              [`& .${lineHighlightElementClasses.root}`]: {
                fill: theme.palette.text.primary,
              },
            }}
          >
            <ColorSwich
              threshold={0}
              id="swich-color-id-1"
              startColor={theme.palette.success.main}
              stopColor={theme.palette.error.main}
            />
            {/* <rect
              x={0}
              y={0}
              width={5}
              height="100%"
              fill="url(#swich-color-id-1)"
            /> */}
          </LineChart>
        </Box>
      </Box>
    </>
  );
};

type ColorSwichProps = {
  threshold: number;
  id: string;
  startColor: string;
  stopColor: string;
};

const ColorSwich: FC<ColorSwichProps> = ({
  threshold,
  id,
  startColor,
  stopColor,
}) => {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  const scale = useYScale();
  const y0 = scale(threshold);
  const off = y0 !== undefined ? y0 / svgHeight : 0;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor={startColor} stopOpacity={0.75} />
        <stop offset={off} stopColor={startColor} stopOpacity={0.25} />
        <stop offset={off} stopColor={stopColor} stopOpacity={0.25} />
        <stop offset={1} stopColor={stopColor} stopOpacity={0.75} />
      </linearGradient>
    </defs>
  );
};
