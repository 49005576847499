import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryAPI } from "../../../../../api/InventoryAPI";
import { candyAPI } from "../../../../../api/CandyAPI";

const queryKeys = [inventoryAPI.QUERY_KEY, candyAPI.QUERY_KEY];

export const useReconcileInventory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inventoryAPI.completeReconciliation,
    onSuccess: () => {
      queryKeys.forEach((key) =>
        queryClient.invalidateQueries({ queryKey: [key] })
      );
    },
  });
};
