import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { SortDirection } from "../api/Page";
import { InventoryItem, inventoryAPI } from "../api/InventoryAPI";

export const useInventory = (
  page: number,
  size: number,
  direction: SortDirection,
  field: "currentStock" | "latestOpened" | "currentExpiry"
) => {
  return useQuery({
    ...inventoryAPI.fetchInventoryList(page, size, direction, field),
    placeholderData: keepPreviousData,
  });
};

export const useSuspendedInventory = (
  page: number,
  size: number,
  direction: SortDirection,
  field: keyof InventoryItem,
) => {
  return useSuspenseQuery(
    inventoryAPI.fetchInventoryList(page, size, direction, field)
  );
};
