import { Alert, AlertTitle, Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useState, useRef, useImperativeHandle } from "react";
import { ArticleId } from "../api/CandyAPI";
import { useTranslation } from "react-i18next";

export interface ScanAlertEntry {
  ean: string;
  articleId?: ArticleId;
  displayName?: string;
}

export type ScanAlertRef = (article: ScanAlertEntry) => void;

interface ScanAlertEntryWithIdAndCount extends ScanAlertEntry {
  id: string;
  count: number;
}

export const SCAN_ALERT_DURATION = 2500;

const randomId = () => Math.random().toString(36).substr(2, 9);

export const ScanAlert = forwardRef<ScanAlertRef>((_, ref) => {
  const [alerts, setAlerts] = useState<ScanAlertEntryWithIdAndCount[]>([]);
  const { t } = useTranslation();

  const alertRef = useRef<ScanAlertRef>((article: ScanAlertEntry) => {
    const id = randomId();

    setAlerts((oldAlerts) => {
      const count =
        oldAlerts.reduce((acc, alert) => {
          if (alert.articleId === article.articleId) {
            return acc + alert.count;
          }
          return acc;
        }, 0) + 1;

      return [
        ...oldAlerts.filter((alert) => alert.articleId !== article.articleId),
        { ...article, id, count },
      ];
    });

    setTimeout(() => {
      setAlerts((oldAlerts) => oldAlerts.filter((alert) => alert.id !== id));
    }, SCAN_ALERT_DURATION);
  });

  useImperativeHandle(ref, () => alertRef.current as ScanAlertRef);

  return (
    <>
      <AnimatePresence>
        {alerts.map((article) => (
          <Box
            component={motion.div}
            sx={{
              background: (theme) => theme.palette.grey[700],
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: 99999,
            }}
            layoutId={article.articleId}
            layout
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: !article.articleId ? 0 : 1,
            }}
            key={article.id}
          >
            <Alert
              severity={article.articleId ? "success" : "warning"}
              variant="filled"
              action={
                <AlertTitle sx={{ p: 2 }}>
                  {article.articleId ? `+${article.count}` : ""}
                </AlertTitle>
              }
            >
              <AlertTitle>
                {article.articleId
                  ? t("Added article")
                  : t("Article not recognized")}
              </AlertTitle>
              {article.articleId && <>{article.displayName || article.ean}</>}
              {!article.articleId && <>{article?.ean}</>}
            </Alert>
          </Box>
        ))}
      </AnimatePresence>
    </>
  );
});
